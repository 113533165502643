const blockedShirts: { [k: number]: any } = {
  12: {
    // Mashbir Kfar Saba
    S: true,
    M: true,
    L: true
  },
  11: {
    XS: true,
    XXL: true
  },
  13: {
    XS: true
  },
  14: {
    XS: true
  },
  16: {
    XS: true,
    XL: true,
    XXL: true
  }
}

export default blockedShirts
