import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  createStyles,
  WithStyles,
  Theme,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
  Chip,
  Avatar,
  Button,
  Box
} from '@material-ui/core'
import classnames from 'classnames'
import ShareIcon from '@material-ui/icons/Share'
import OrderSummary from '../components/OrderSummary'
import FallenCard from '../components/FallenCard'
import Section from '../components/Section'
import OptionalEdit from '../components/OptionalEdit'
import PersonIcon from '@material-ui/icons/Person'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import CopyToClipboard from 'react-copy-to-clipboard'
import UserDataContext from '../utils/UserDataContext'

import DoneIcon from '@material-ui/icons/Done'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FacebookIcon from '@material-ui/icons/Facebook'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import FileCopy from '@material-ui/icons/FileCopy'

import { Order, hasShirts, shirts, peopleLength, stores, Option } from 'shared'
import { format } from 'date-fns'
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next'
import ShirtSizeEdit from '../components/ShirtSizeEdit'
import CollectionEdit from '../components/CollectionEdit'
import history from '../utils/history'

import WorldZionistImg from '../assets/world-zionist.jpeg'
import Barcode from 'react-barcode'

const styles = (theme: Theme) =>
  createStyles({
    panel: {
      width: '100%'
    },
    root: {
      backgroundColor: theme.palette.primary.main
    },
    expandIcon: {
      color: '#FFFFFF'
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    noPadding: {
      padding: 0
    },
    subtitle1: {
      margin: theme.spacing(1, 0, 0, 0),
      fontSize: '.875rem',
      color: '#000000'
    },
    chip: {
      margin: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      color: '#FFFFFF'
      // flexBasis: '33.33%',
      // flexShrink: 0
    },
    actionButtons: {
      textAlign: 'center',
      margin: theme.spacing(0, 0, 1),
      [theme.breakpoints.up('sm')]: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4)
      }
    },
    actionButton: {
      color: 'white'
    },
    bold: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark
    },
    shirtsTitle: {
      color: '#FFFFFF',
      fontSize: '1.125rem',
      fontWeight: 'bold',
      textAlign: 'center',
      margin: theme.spacing(4, 0, 2)
    },
    sectionSubtitle: {
      margin: theme.spacing(4, 0, 1),
      color: '#424242',
      fontSize: '.875rem',
      fontWeight: 'bold'
    },
    codeCollection: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 'bold'
    },
    iconButton: {
      color: '#FFFFFF',
      margin: theme.spacing(0),
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    patrocinio: {
      color: '#FFFFFF',
      margin: theme.spacing(0),
      fontWeight: 'bold',
      fontSize: '1rem',
      textAlign: 'center'
    },
    share: {
      fontWeight: 'bold'
    },
    shareLabel: {
      color: 'white'
    },
    worldLogo: {
      margin: 'auto',
      width: '300px',
      display: 'block'
    }
  })

const RegistrationInfo: React.SFC<WithStyles<typeof styles> & {
  orders: Order[]
} & WithNamespaces> = ({ classes, orders, t }) => {
  return (
    <UserDataContext.Consumer>
      {({ setCollectionLocation, setShirtSize }) => (
        <>
          {/* Show world zionist logo if country is not il */}
          {!!orders.find(order => order.country !== 'il') && (
            <div style={{ margin: '10px 0' }}>
              <Typography variant="body2" className={classes.patrocinio}>
                {t('registrationInfo.patrocinio', {
                  defaultValue: 'בשיתוף פעולה עם'
                })}
              </Typography>
              <img
                alt="world-zionist"
                src={WorldZionistImg}
                className={classes.worldLogo}
              />
            </div>
          )}
          {orders
            .map(ord => ord.registrations)
            .reduce((acc, val) => acc.concat(val), [])
            .map((registration, idx) => (
              <FallenCard
                key={registration.uid}
                registration={registration}
                position={idx + 1}
              />
            ))}
          {!!orders.filter(order => hasShirts(order)).length && (
            <Typography variant="body2" className={classes.shirtsTitle}>
              {t('registrationInfo.shirtsCollectionTitle', {
                defaultValue: 'אלו הפרטים שמוזנים, ניתן לערוך אותם בכל עת:'
              })}
            </Typography>
          )}
          {orders
            .map((order, idx) =>
              hasShirts(order) ? (
                <div key={order.uid}>
                  <Section
                    title={`${t('registrationInfo.shirtCollection', {
                      defaultValue: 'ההזמנה שלך'
                    })} (${idx + 1})`}
                    grid={{ xs: 12, sm: 12, lg: 12, xl: 12 }}
                    icon={<MarkunreadMailboxIcon />}
                  >
                    <Typography
                      variant="body2"
                      className={classes.sectionSubtitle}
                    >
                      {t('registrationInfo.sectionShirts', {
                        defaultValue: 'חולצות'
                      })}
                    </Typography>
                    <ShirtSizeEdit order={order} onSizeChange={setShirtSize} />
                    <Typography
                      variant="body2"
                      className={classes.sectionSubtitle}
                    >
                      {t('registrationInfo.sectionCollection', {
                        defaultValue: 'איסוף'
                      })}
                    </Typography>
                    <CollectionEdit
                      order={order}
                      onCollectionChange={setCollectionLocation}
                    />
                    <div>
                      <Typography
                        variant="body2"
                        className={classes.codeCollection}
                      >
                        {t('registrationInfo.codeCollection', {
                          defaultValue: 'קוד לאיסוף ההזמנה:'
                        })}
                        {shirts(order).map(({ size, uid }, idx) => (
                          <Box
                            key={uid}
                            style={{ textAlign: 'center', margin: '6px 0 6px' }}
                          >
                            <Barcode value={order.coupons![idx]} />
                          </Box>
                        ))}
                      </Typography>
                      <Typography variant="body2" className={classes.subtitle1}>
                        {t('registrationInfo.shirtCollectionDate', {
                          defaultValue:
                            'ניתן לאסוף את החולצות בנקודת האיסוף שנבחרה החל מתאריך'
                        })}{' '}
                        {process.env.REACT_APP_SHIRTS_AVAILABILITY_DATE}
                        {'. '}
                        {t('registrationInfo.shirtCollectionAlert', {
                          defaultValue: 'בהצגת קוד האיסוף.'
                        })}
                      </Typography>
                    </div>
                  </Section>
                </div>
              ) : null
            )
            .filter(cmp => !!cmp)}
          <div className={classes.actionButtons}>
            <Button
              className={classes.share}
              classes={{
                label: classes.shareLabel
              }}
              color="secondary"
              disabled
            >
              {t('page.toolbar.share', { defaultValue: 'שיתוף:' })}
            </Button>
            <Button
              className={classes.iconButton}
              onClick={() => {
                window.FB.ui({
                  method: 'share',
                  href: process.env.REACT_APP_INDEX_APP_URL,
                  hashtag: process.env.REACT_APP_HASHTAG,
                  mobile_iframe: true,
                  quote: t('registrationInfo.shareQuestion', {
                    defaultValue:
                      'השנה כולנו רצים לזכרם. אני כבר נרשמתי, מה אתך?'
                  })
                })
              }}
            >
              <FacebookIcon />
            </Button>
            <Button
              className={classes.iconButton}
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?text=${t(
                    'registrationInfo.shareQuestion',
                    {
                      defaultValue:
                        'השנה כולנו רצים לזכרם. אני כבר נרשמתי, מה אתך?'
                    }
                  )} ${encodeURIComponent(
                    process.env.REACT_APP_INDEX_APP_URL as string
                  )}`
                )
              }}
            >
              <WhatsAppIcon />
            </Button>
          </div>
        </>
      )}
    </UserDataContext.Consumer>
  )
}

export default withNamespaces()(withStyles(styles)(RegistrationInfo))
