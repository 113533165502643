import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Button,
  IconButton,
  WithStyles,
  createStyles,
  Badge,
  Theme,
  Tooltip
} from '@material-ui/core'
import {
  Mail,
  Menu,
  ExitToApp,
  Language,
  Info,
  Person,
  PersonAdd
} from '@material-ui/icons'
import classnames from 'classnames'
import { WithAuthenticationRenderProps } from '../utils/WithAuthentication'
import history from '../utils/history'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import i18next from 'i18next'
import { toggleLanguage, lng } from '../i18n'
import LogoHeader from '../assets/logo-white@2x.png'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import qs from 'query-string'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    grow: {
      fontWeight: 'bold',
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        flexGrow: 'inherit'
      }
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    content: {
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    tooltip: {
      fontSize: 18
    },
    appBar: {
      boxShadow: 'none',
      backgroundColor: '#067DAD',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'transparent'
      }
    },
    toolbar: {
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4)
      }
    },
    btnActiveLng: {
      backgroundColor: 'white'
    },
    btnLng: {
      padding: 0
    },
    btnLabel: {
      fontWeight: 'bold',
      padding: 0
    },
    btnDisabled: {
      color: `${theme.palette.primary.main} !important`
    },
    redirectBtn: {
      fontWeight: 'bold',
      marginRight: theme.spacing(4)
    },
    logo: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    logoImg: {
      width: '141px'
    },
    iconButton: {
      color: '#FFFFFF',
      margin: theme.spacing(0, 0, 0, 0),
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    icon: {
      marginRight: theme.spacing(1)
    }
  })

const pageTitle = (pathname: string, t: i18next.TFunction): string => {
  if (pathname === '/') {
    return t('page.title.home', { defaultValue: 'נתוני הרשמה' })
  } else if (pathname === '/registration') {
    return t('page.title.registration', { defaultValue: 'טופס הרשמה' })
  }

  return t('page.title.default', { defaultValue: 'רצים לזכרם' })
}

const NavigationBar: React.SFC<WithStyles<typeof styles> &
  WithAuthenticationRenderProps & {
    pathname: string
    isThankYou?: boolean
  } & WithNamespaces> = ({
  classes,
  children,
  signOut,
  orders,
  isThankYou,
  currentUser,
  pathname,
  t
}) => {
  const { name, phone, org } = qs.parse(window.location.search)

  return (
    <div className={classes.root}>
      {/* {name && (
        <AppBar position="static" color="default">
          <Toolbar>
            <Typography variant="h6" color="error">
              {t('page.debugbar.title1', {
                defaultValue: 'נתונים עבוד משתמש'
              })}{' '}
              {name} : {phone}{' '}
              {t('page.debugbar.title2', {
                defaultValue: 'תזהר בכל שינוי כי זה משנה את הנתונים של המשתמש'
              })}
            </Typography>
          </Toolbar>
        </AppBar>
      )} */}
      <AppBar
        position="static"
        className={classnames(
          'print-hidden',
          'print-before-hidden',
          classes.appBar
        )}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <img src={LogoHeader} className={classes.logoImg} />
          </div>

          <Typography variant="h6" color="inherit" className={classes.grow}>
            <Button
              className={classes.redirectBtn}
              color="secondary"
              size="large"
              onClick={() => {
                window.open('https://www.memorun.org', '_blank')
              }}
            >
              {t('page.toolbar.site', { defaultValue: 'חזרה לאתר' })}
            </Button>
          </Typography>
          {pathname !== '/registration' && (
            <Button
              className={classes.iconButton}
              onClick={() => {
                history.push(`/registration${org ? `?org=${org}` : ''}`)
              }}
            >
              <AddCircleIcon className={classes.icon} />
              {t('registration.addNew', {
                defaultValue: 'משתתף/ת נוסף/ת'
              })}
            </Button>
          )}
          {!!orders.length && pathname === '/registration' && (
            <Button
              className={classes.iconButton}
              onClick={() => {
                history.push(`/${org ? `?org=${org}` : ''}`)
              }}
            >
              <ExitToAppIcon className={classes.icon} />
              {t('registration.backToOrder', {
                defaultValue: 'להרשמה הקודמת'
              })}
            </Button>
          )}
          <Button
            classes={{
              root: classes.btnLng,
              label: classes.btnLabel,
              disabled: classes.btnDisabled
            }}
            color="secondary"
            size="large"
            onClick={() =>
              lng() === 'he' ? toggleLanguage('en') : toggleLanguage('he')
            }
          >
            {lng() === 'he' ? 'EN' : 'עברית'}
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classnames('print-content-area', classes.content)}>
        <Grid
          spacing={2}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          {children}
        </Grid>
      </main>
    </div>
  )
}

export default withNamespaces()(withStyles(styles)(NavigationBar))
