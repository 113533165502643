import React, { Component } from 'react'
import { init, search } from 'utils/search'
import { fallenParentsName } from 'shared'
import { isEnglish } from '../../i18n'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import throttle from 'lodash.throttle'

function FallenAutocompleteField({
  label,
  t,
  onChange,
  value
}: {
  label: string
  onChange: (value: any) => void
  value: any
} & WithNamespaces) {
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<any[]>([])

  React.useEffect(() => {
    init()
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (request: { input: string }, callback: (results?: any[]) => void) => {
          search(request.input).then(({ hits }) => {
            callback(
              hits.map((hit: any) => ({
                value: hit.uuid,
                label: `${
                  isEnglish() ? hit.en.fullName : hit.fullName
                } ${fallenParentsName(hit, isEnglish())}`,
                fallen: hit
              }))
            )
          })
        },
        200
      ),
    [isEnglish()]
  )

  React.useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions([])
      return undefined
    }

    fetch({ input: inputValue }, (results?: any[]) => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      disableOpenOnFocus
      // getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.label}
      options={options}
      noOptionsText={t('selectField.noResults', {
        defaultValue: 'לא נמצאו תוצאות'
      })}
      // loading={loading}
      onChange={(event: any, value: any) => {
        onChange(value)
      }}
      value={value}
      loadingText={t('selectField.loading', { defaultValue: 'טוען נתונים...' })}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          onChange={handleChange}
          fullWidth
          InputProps={{
            ...params.InputProps
            // endAdornment: (
            //   <>
            //     {loading ? (
            //       <CircularProgress color="inherit" size={20} />
            //     ) : null}
            //     {params.InputProps.endAdornment}
            //   </>
            // )
          }}
        />
      )}
    />
  )
}

export default withNamespaces()(FallenAutocompleteField)
