import React, { Component } from 'react'

import {
  Typography,
  Grid,
  Button,
  createStyles,
  Container,
  Theme,
  WithStyles
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import qs from 'query-string'
import history from '../utils/history'

import Alert from '../components/Alert'
import RegistrationInfo from '../components/RegistrationInfo'
import ThankYou from '../components/ThankYou'
import { Order } from 'shared'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 10,
      textAlign: 'center'
    },
    thankyou: {
      margin: '10px auto'
    },
    error: {
      color: theme.palette.error.main
    },
    icon: {
      marginRight: theme.spacing(1)
    },
    text: {
      margin: theme.spacing(1, 0, 0, 0),
      fontSize: '1.125rem',
      textAlign: 'center',
      fontWeight: 'bold',
      color: 'white'
    },
    noPadding: {
      padding: 0
    },
    subtitle1: {
      marginBottom: 10
    },
    chip: {
      margin: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15)
      // flexBasis: '33.33%',
      // flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },
    bold: {
      fontWeight: 'bold',
      color: theme.palette.primary.dark
    },
    margin: {
      margin: theme.spacing(1)
    },
    buttonContainer: {
      width: '100%',
      textAlign: 'center'
    },
    info: {
      marginBottom: theme.spacing(2)
    }
  })

class Home extends Component<
  WithStyles<typeof styles> & { orders: Order[] } & WithNamespaces,
  { hideThankYou: boolean }
> {
  state = {
    hideThankYou: false
  }

  handleNewRegistration = () => {
    history.push('/registration')
  }

  handleHideThankYou = () => {
    this.setState(() => ({ hideThankYou: true }))
  }

  render() {
    const { classes, orders, t } = this.props

    const { hideThankYou } = this.state
    const { thankyou } = qs.parse(history.location.search)

    return (
      <Container maxWidth="sm">
        {thankyou && !hideThankYou && (
          <Grid item xs={12}>
            <ThankYou/>
          </Grid>
        )}
        {/* <Grid item xs={12} className={classes.title}>
          {orders.length ? (
            <>
              <Typography variant="h6" gutterBottom>
                {t('home.descriptionWithRegistration', {
                  defaultValue: 'ברוכים הבאים .להלן נתוני ההרשמה שלכם למיזם.'
                })}
              </Typography>
              <Button color="primary" onClick={this.handleNewRegistration}>
                <PersonAddIcon className={classes.icon} />
                {t('home.makeAdditionalRegistration', {
                  defaultValue: 'לביצוע הרשמה נוספת'
                })}
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                {t('home.descriptionNoRegistration', {
                  defaultValue: 'ברוכים הבאים .אין לכם הרשמה למיזם.'
                })}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleNewRegistration}
              >
                <PersonAddIcon className={classes.icon} />
                {t('home.makeRegistration', {
                  defaultValue: 'לביצוע הרשמה למיזם'
                })}
              </Button>
            </>
          )}
        </Grid> */}
        {/* {orders.map((order, idx) => ( */}
        <RegistrationInfo orders={orders} />
        {/* ))} */}
      </Container>
    )
  }
}

export default withNamespaces()(withStyles(styles)(Home))
