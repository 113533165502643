import React, { Component } from 'react'
import {
  Button,
  TextField,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
  LinearProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withNamespaces, Trans } from 'react-i18next'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { lng } from '../i18n.ts'
import { WithoutFormikPhoneTextField } from '../components/PhoneTextField'

// const normaliseAndVerifyPhone = (phone, t) => {
//   const cleanPhone = phone.trim().replace(/[-|(|)]/g, '')

//   if (/\D/.test(cleanPhone.replace(/^\+/, ''))) {
//     const error = new Error(
//       t('authentication.validationErrorPhone', {
//         defaultValue: 'מספר טלפון לא חוקי'
//       })
//     )
//     error.code = 'validation/invalid-phone-number'
//     throw error
//   }

//   return cleanPhone
// }

const styles = theme => ({
  paper: {
    margin: theme.spacing(4, 0, 0),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4)
    }
  },
  title: {
    fontWeight: 'bold'
  },
  text: {
    fontSize: '1.125rem'
  },
  textfield: {
    margin: theme.spacing(4, 0)
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0, 0, 2, 0)
  },
  button: {
    fontWeight: 'bold'
  },
  reset: {
    margin: theme.spacing(2),
    color: 'white',
    textAlign: 'center',
    cursor: 'pointer'
  },
  resetBold: {
    textDecoration: 'underline',
    fontWeight: 'bold'
  }
})

class Authentication extends Component {
  state = {
    codeSent: false,
    code: '',
    isProcessing: false,
    phoneNumber: '',
    phoneError: undefined
  }

  componentDidMount() {
    setTimeout(() => {
      // Set as hebrew
      window.firebase.auth().languageCode = lng() === 'he' ? 'iw' : 'en'

      window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
        this.recaptchaRef,
        {
          size: 'invisible',
          badge: lng() === 'en' ? 'bottomright' : 'bottomleft',
          callback: () => {
            // calling handle action
            this.handleAction()
          }
        }
      )

      window.recaptchaVerifier.render().then(widgetId => {
        window.recaptchaWidgetId = widgetId
      })
    }, 0)

    window.firebase.auth().onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        this.setState(() => ({ isProcessing: false }))
      }
    })
  }

  handleAction = async () => {
    const { t } = this.props
    const { phoneNumber, codeSent, code } = this.state

    try {
      this.setState(() => ({ isProcessing: true }))

      if (!codeSent) {
        // Send code to phone

        window.confirmationResult = await window.firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)

        this.setState(() => ({
          codeSent: true,
          phoneNumber,
          isProcessing: false
        }))
      } else {
        // Verify code sent, don't set it is process to false yet,
        //  wait for user authentication to happen so to load the user
        await window.confirmationResult.confirm(code)
      }
    } catch (error) {
      window.Raven.captureException(error)
      this.setState(() => ({
        isProcessing: false,
        phoneError: !/^validation/.test(error.code)
          ? new Error(
              t('authentication.validationErrorGeneral', {
                defaultValue:
                  'לא הצלחנו לאמת את משתמש או לשלוח את קוד אימות. נסה שוב מאוחר יותר.'
              })
            )
          : error
      }))
    }
  }

  handleReset = () => {
    this.setState(() => ({
      codeSent: false,
      code: '',
      isProcessing: false,
      phoneNumber: '',
      phoneError: undefined
    }))
  }

  handlePhoneChange = value => {
    const invalidError = new Error(
      this.props.t('authentication.validationErrorPhone', {
        defaultValue: 'מספר טלפון לא חוקי'
      })
    )
    invalidError.code = 'validation/invalid-phone-number'

    this.setState(() => ({
      phoneNumber: value,
      phoneError: !value || isValidPhoneNumber(value) ? undefined : invalidError
    }))
  }

  handleCodeChange = ({ target: { value } }) => {
    this.setState(() => ({ code: value }))
  }

  render() {
    const { classes, t } = this.props
    const { codeSent, code, phoneNumber, phoneError, isProcessing } = this.state

    return (
      <Container maxWidth="md">
        {isProcessing && <LinearProgress />}
        <Paper className={classes.paper}>
          <Typography className={classes.title}>
            {t('authentication.title', { defaultValue: 'אימות משתמש' })}
          </Typography>
          <Typography className={classes.text}>
            {!codeSent ? (
              <Trans i18nKey="authentication.welcome">
                אנא הקלידו מספר טלפון נייד, לאחר ההקלדה ישלח למספר זה קוד אימות
              </Trans>
            ) : (
              <Trans i18nKey="authentication.codeSent">
                על מנת להמשיך בתהליך הרישום, הקלידו את הקוד שקיבלתם במסרון
                לטלפון הנייד
              </Trans>
            )}
          </Typography>

          {!codeSent && (
            <WithoutFormikPhoneTextField
              autoFocus
              error={!!phoneError}
              id="phone"
              label={t('authentication.phone', {
                defaultValue: 'מספר הנייד שלך'
              })}
              disabled={codeSent || isProcessing}
              value={phoneNumber}
              onChange={this.handlePhoneChange}
            />
            // <TextField
            //   autoFocus
            //   error={!!phoneError}
            //   margin="dense"
            //   id="phone"
            //   label={t('authentication.phone', {
            //     defaultValue: 'מספר הנייד שלך'
            //   })}
            //   type="text"
            //   fullWidth
            //   disabled={codeSent || isProcessing}
            //   value={phoneNumber}
            //   className={classes.textfield}
            //   onChange={this.handlePhoneChange}
            // />
          )}
          {codeSent && (
            <TextField
              autoFocus
              disabled={isProcessing}
              error={!!phoneError}
              margin="dense"
              id="code"
              label={t('authentication.smsCode', {
                defaultValue: 'הקוד שהתקבל'
              })}
              fullWidth
              value={code}
              className={classes.textfield}
              onChange={this.handleCodeChange}
            />
          )}
          {phoneError && (
            <Typography
              variant="body2"
              className={classes.error}
              align="center"
            >
              {phoneError.message}
            </Typography>
          )}
          <div>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              id="send-code-button"
              buttonRef={ref => {
                this.recaptchaRef = ref
              }}
              style={{ display: !codeSent ? 'block' : 'none' }}
              type="submit"
              disabled={!phoneNumber || codeSent || isProcessing}
            >
              {t('authentication.sendMeCode', {
                defaultValue: 'שלחו לי קוד'
              })}
            </Button>
            {codeSent && (
              <Button
                fullWidth
                className={classes.button}
                onClick={this.handleAction}
                color="primary"
                variant="contained"
                disabled={!code || isProcessing}
              >
                {t('authentication.validateCode', {
                  defaultValue: 'אישור'
                })}
              </Button>
            )}
          </div>
        </Paper>
        {codeSent && !isProcessing && (
          <Typography className={classes.reset} onClick={this.handleReset}>
            {t('authentication.startAgain', {
              defaultValue: 'לא קיבלתי קוד,'
            })}{' '}
            <span className={classes.resetBold}>
              {t('authentication.startAgain2', {
                defaultValue: 'רוצה להתחיל שוב'
              })}
            </span>
          </Typography>
        )}
      </Container>
    )
  }
}

export default withNamespaces()(
  withStyles(styles, { withTheme: true })(Authentication)
)
