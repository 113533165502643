import { Registration } from './registration'

export const PRICES_BY_COUNTRY = {
  il: {
    PRICE_SHIRT: 40,
    PRICE_NO_SHIRT: 10,
    CURRENCY: '₪'
  },
  fr: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '€'
  },
  de: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '€'
  },
  other: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '€'
  },
  uk: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '£'
  },
  us: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '$'
  },
  eu: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '€'
  },
  la: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '$'
  },
  asia: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '$'
  },
  au: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '$'
  },
  africa: {
    PRICE_SHIRT: 3,
    PRICE_NO_SHIRT: 3,
    CURRENCY: '$'
  }
}

export type Option = {
  value: string
  label: string
}

export type Activity = {
  type: string
  distance: string
  group: string
  location?: Option
}

type Transaction = {
  acode: string
  amount: number
  ccode: string
  yaadId: string
}

type Country = 'il' | 'uk' | 'de' | 'fr' | 'us' | 'other'

export type Order = {
  uid?: string
  name: string
  email: string
  phone: string
  country: Country
  coupons?: string[]
  activity: Activity
  registrations: Registration[]
  collectionLocation?: Option
  transaction?: Transaction
  finalizedAt?: Date
  createdBy?: string
  org?: string
}

const storeToName = (store?: {
  code: number
  city: string
  address: string
  storeName: string
}) => (store ? `${store.storeName}, ${store.address} ${store.city}` : '-')

export const price = (order: Order): number => {
  const people = peopleLength(order)
  const shirts = shirtsLength(order)
  const registrationOnly = people - shirts
  return (
    shirts * PRICES_BY_COUNTRY[order.country].PRICE_SHIRT +
    (registrationOnly < 0 ? 0 : registrationOnly) *
      PRICES_BY_COUNTRY[order.country].PRICE_NO_SHIRT
  )
}

export const priceBySize = (
  country: Country,
  quantity: number,
  shirtSize?: string
): number => {
  return (
    (shirtSize != 'noShirt'
      ? PRICES_BY_COUNTRY[country].PRICE_SHIRT
      : PRICES_BY_COUNTRY[country].PRICE_NO_SHIRT) * quantity
  )
}

export const currency = (country: Country): string =>
  PRICES_BY_COUNTRY[country].CURRENCY

export const shirtSizesDescription = (order: Order): string => {
  const shirtsQuantity: { [size: string]: number } = shirts(order).reduce(
    (acc: { [size: string]: number }, { size }) => {
      acc[size] = (acc[size] || 0) + 1
      return acc
    },
    {}
  )

  return Object.keys(shirtsQuantity)
    .map(size => `${size}: ${Math.abs(shirtsQuantity[size])}`)
    .join(', ')
}

export const shirtSizesGroup = (order: Order): { [size: string]: number } => {
  return order.registrations.reduce(
    (acc: { [size: string]: number }, { size, noShirt }) => {
      const attr = noShirt ? 'noShirt' : size
      acc[attr] = (acc[attr] || 0) + 1
      return acc
    },
    {}
  )
}

export const peopleLength = (order: Order): number => order.registrations.length

export const hasShirts = (order: Order): boolean => !!shirtsLength(order)

export const shirtCollectionAddress = (order: Order): string =>
  order.collectionLocation ? order.collectionLocation.label : '-'

export const shirtsLength = (order: Order) => shirts(order).length

export const shirts = (order: Order): Registration[] =>
  order.registrations.filter(reg => !reg.noShirt)

export const finalizedOrderedOrders = (orders: Order[]) =>
  orders
    .filter(order => !!order.finalizedAt)
    .sort((a, b) =>
      b.finalizedAt!.getTime() > a.finalizedAt!.getTime() ? 1 : -1
    )
