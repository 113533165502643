export const ShirtSizeArray = ['XS', 'S', 'M', 'L', 'XL', 'XXL']
export type ShirtSize = typeof ShirtSizeArray[number]

export type Fallen = {
  defaultSearchField: string
  fullName: string
  firstName: string
  lastName: string
  uuid: string
  orderId: number
  fatherName?: string
  motherName?: string
  birthCity?: string
  cemeteryName?: string
  deathDate?: string
  deathDateHebrew?: string
  gender: 'ז' | 'נ'
  legacyId: string
  nickname?: string
  rank?: string
  unit?: string
  en: {
    defaultSearchField: string
    fullName: string
    firstName: string
    lastName: string
    fatherName?: string
    motherName?: string
    deathDate?: string
    deathDateHebrew?: string
  }
}

export type Registration = {
  uid?: string
  size: ShirtSize
  noShirt: boolean
  fallen?: {
    main: Fallen
    selected?: Fallen | string
  }
  org?: string
  country?: string
}

const extraEncodeURIComponent = (comp: string) =>
  encodeURIComponent(comp)
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/'/g, '%27')

export const fallenIzkorLink = (fallen: Fallen, isEnglish = false) =>
  `https://www.izkor.gov.il${
    isEnglish ? '/en/fallen/remembering/' : '/זוכרים-את/'
  }${fallen.uuid}`

export const racebibLink = ({
  main,
  selected,
  language,
  encodeLinks,
  org,
  country,
  disableDialog
}: {
  main: Fallen
  selected?: Fallen | string
  language?: string
  encodeLinks?: boolean
  org?: string
  country?: string
  disableDialog?: boolean
}): string => {
  const mainFullName = language === 'en' ? main.en.fullName : main.fullName

  const selectedName = selected
    ? typeof selected === 'string'
      ? selected
      : language === 'en' && selected.en
      ? selected.en.fullName
      : selected.fullName
    : ''

  const selectedUuid =
    selected && typeof selected !== 'string' ? selected.uuid : undefined

  return `https://racebib.memorun.org/?lng=${language || 'he'}&f1Name=${
    encodeLinks ? extraEncodeURIComponent(mainFullName) : mainFullName
  }&f1=${main.uuid}&f2Name=${
    encodeLinks ? extraEncodeURIComponent(selectedName) : selectedName
  }${selectedUuid ? '&f2=' + selectedUuid : ''}${org ? `&org=${org}` : ''}${
    country ? `&country=${country}` : ''
  }${disableDialog ? `&ddisabled=true` : ''}`
}

export const shareLink = ({
  main,
  selected,
  language,
  encodeLinks
}: {
  main: Fallen
  selected?: Fallen | string
  language?: string
  encodeLinks?: boolean
}): string => {
  const mainFullName = language === 'en' ? main.en.fullName : main.fullName

  const selectedName = selected
    ? typeof selected === 'string'
      ? selected
      : language === 'en' && selected.en
      ? selected.en.fullName
      : selected.fullName
    : ''

  return `https://us-central1-memorun-2020-production.cloudfunctions.net/share?f1Name=${
    encodeLinks ? extraEncodeURIComponent(mainFullName) : mainFullName
  }&f2Name=${
    encodeLinks ? extraEncodeURIComponent(selectedName) : selectedName
  }&lng=${language}`
}

export const fallenParentsName = (
  fallen: Fallen,
  isEnglish = false
): string | null => {
  if (!fallen.fatherName && !fallen.motherName) {
    return ''
  }

  if (isEnglish) {
    return `${fallen.gender === 'ז' ? 'Son of' : 'Daughter of'} ${fallen.en
      .motherName || ''}${fallen.en.motherName ? ' and ' : ''}${fallen.en
      .fatherName || ''}`
  } else {
    return `${fallen.gender === 'ז' ? 'בן' : 'בת'} ${fallen.motherName || ''}${
      fallen.motherName ? ' ו' : ''
    }${fallen.fatherName || ''}`
  }
}

export const fallenDeathDate = (
  fallen: Fallen,
  isEnglish = false
): string | null => {
  if (!fallen.deathDateHebrew && !fallen.deathDate) {
    return null
  }

  if (isEnglish) {
    return `Fell on ${fallen.en.deathDateHebrew || ''}${
      fallen.en.deathDate ? ' ' : ''
    }(${fallen.en.deathDate ? fallen.en.deathDate.replace(/-/g, '.') : ''}${
      fallen.en.deathDate ? '' : ''
    })`
  } else {
    return `${
      fallen.gender === 'ז' ? 'נפל' : 'נפלה'
    } ${'ביום '}${fallen.deathDateHebrew || ''}${fallen.deathDate ? ' ' : ''}${
      fallen.deathDate ? fallen.deathDate.replace(/-/g, '.') : ''
    }${fallen.deathDate ? '' : ''}`
  }
}
