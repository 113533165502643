import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import OptionalEdit from '../components/OptionalEdit'

import {
  createStyles,
  WithStyles,
  Theme,
  TextField,
  Button,
  MenuItem
} from '@material-ui/core'

import {
  Order,
  hasShirts,
  shirts,
  ShirtSizeArray,
  ShirtSize,
  Registration
} from 'shared'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      // marginTop: 10
    },
    box: {
      position: 'relative',
      backgroundColor: '#ECEFF0',
      padding: theme.spacing(4),
      margin: theme.spacing(0, 0, 4, 0),
      borderRadius: '5px'
    },
    label: {
      color: '#424242',
      fontWeight: 'bold'
    },
    buttonLink: {
      position: 'absolute',
      zIndex: 1000,
      right: 0,
      top: '15px',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      fontSize: '.875rem',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline'
    }
  })

class ShirtSizeEdit extends Component<
  WithStyles<typeof styles> & {
    order: Order
    onSizeChange: (registration: Registration, size: ShirtSize) => void
  } & WithNamespaces,
  {
    editing: { [key: string]: boolean }
  }
> {
  state: {
    editing: { [key: string]: boolean }
  } = {
    editing: {}
  }

  toggleEditing = (idx: string) => {
    this.setState(({ editing }) => ({
      editing: { ...editing, [idx]: !editing[idx] }
    }))
  }

  render() {
    const { t, order, classes, onSizeChange } = this.props
    const { editing } = this.state

    return (
      <>
        {shirts(order).map((registration, idx) => (
          <div key={idx} className={classes.box}>
            {!editing[`${idx}`] && (
              <Button
                className={classes.buttonLink}
                color="primary"
                onClick={() => {
                  this.toggleEditing(`${idx}`)
                }}
              >
                {/* <Info className={classes.leftIcon} /> */}
                {t('shirtField.change', {
                  defaultValue: 'עריכה'
                })}
              </Button>
            )}
            <TextField
              key={registration.uid}
              select={editing[`${idx}`]}
              label={`${t('shirtField.registration', {
                defaultValue: 'משתתף/ת'
              })} ${idx + 1}*`}
              className={classes.textField}
              value={registration.size}
              onChange={event => {
                this.toggleEditing(`${idx}`)
                onSizeChange(registration, event.target.value)
              }}
              fullWidth
              margin="none"
              InputLabelProps={{
                className: classes.label
              }}
              InputProps={{
                disabled: !editing[`${idx}`]
              }}
            >
              {editing[`${idx}`] &&
                ShirtSizeArray.map(sizeValue => (
                  <MenuItem key={sizeValue} value={sizeValue}>
                    {sizeValue}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        ))}
      </>
    )
  }
}

export default withNamespaces()(withStyles(styles)(ShirtSizeEdit))
