import React, { useEffect } from 'react'
import {
  Typography,
  Switch,
  createStyles,
  WithStyles,
  TextField,
  Theme,
  MenuItem,
  IconButton,
  FormGroup,
  Chip,
  Avatar,
  Paper,
  FormControlLabel
} from '@material-ui/core'
import FaceIcon from '@material-ui/icons/Face'
import { withStyles } from '@material-ui/core/styles'
import { ShirtSize, ShirtSizeArray, Registration } from 'shared'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ECEFF0',
      borderRadius: '5px',
      padding: theme.spacing(4),
      marginTop: theme.spacing(2),
      position: 'relative'
    },
    chip: {
      marginBottom: theme.spacing(1),
      fontSize: 14
    },
    label: {
      fontWeight: 'bold'
    },
    deleteBtn: {
      position: 'absolute',
      top: 0,
      right: 0
    }
  })

export type ShirtFieldProps = {
  position: number
  onSizeChange: (size: ShirtSize) => void
  onToggleNoShirt?: (noShirt: boolean) => void
  onlyNoShirt?: boolean
  onDelete: () => void
} & Registration

const ShirtField: React.SFC<WithStyles<typeof styles> &
  ShirtFieldProps &
  WithNamespaces> = ({
  classes,
  position,
  noShirt,
  onSizeChange,
  onDelete,
  onlyNoShirt,
  onToggleNoShirt,
  size,
  t
}) => {
  // Change value for without shirt if no shirt size allowed
  useEffect(() => {
    if (onlyNoShirt && onSizeChange && !noShirt) {
      onSizeChange('noShirt')
    }
  }, [onSizeChange, onlyNoShirt, noShirt])

  return (
    <Paper className={classes.root} elevation={0}>
      {/* <Chip
      avatar={
        <Avatar>
          <FaceIcon />
        </Avatar>
      }
      label={`${t('shirtField.registration', {
        defaultValue: 'משתתף/ת'
      })} ${position}`}
      onDelete={position !== 1 && onDelete ? onDelete : undefined}
      className={classes.chip}
      color="primary"
    /> */}
      {position !== 1 && onDelete && (
        <IconButton
          className={classes.deleteBtn}
          key="close"
          size="small"
          aria-label="Close"
          color="primary"
          onClick={onDelete}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      <TextField
        id="outlined-select-currency"
        select
        label={`${t('shirtField.registration', {
          defaultValue: 'משתתף/ת'
        })} ${position}${position === 1 ? '*' : ''}`}
        // className={classes.textField}
        value={noShirt || onlyNoShirt ? 'noShirt' : size}
        onChange={event => {
          onSizeChange(event.target.value)
        }}
        InputLabelProps={{
          className: classes.label
        }}
        SelectProps={{
          IconComponent: onlyNoShirt ? () => null : undefined
        }}
        // SelectProps={{
        //   MenuProps: {
        //     className: classes.menu
        //   }
        // }}
        fullWidth
        disabled={onlyNoShirt}
        // helperText="Please select your currency"
        margin="none"
        // variant="outlined"
      >
        {!onlyNoShirt &&
          ShirtSizeArray.map(sizeValue => (
            <MenuItem key={sizeValue} value={sizeValue}>
              {sizeValue}
            </MenuItem>
          ))}
        <MenuItem value="noShirt">
          {t('shirtField.noShirt', {
            defaultValue: 'ללא חולצה'
          })}
        </MenuItem>
      </TextField>
      {/* <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={noShirt}
            onChange={() => onToggleNoShirt(!noShirt)}
            color="primary"
            value="noShirt"
          />
        }
        label={t('shirtField.withoutShirt', {
          defaultValue: 'לקבלת שם נופל/ת ללא הזמנת חולצה'
        })}
      />
    </FormGroup> */}
    </Paper>
  )
}

export default withNamespaces()(withStyles(styles)(ShirtField))
