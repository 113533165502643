import * as React from 'react'
import {
  MuiThemeProvider,
  createMuiTheme,
  jssPreset,
  StylesProvider
} from '@material-ui/core/styles'
// eslint-disable-next-line
import { create } from 'jss'
import rtl from 'jss-rtl'
import CssBaseline from '@material-ui/core/CssBaseline'
import pink from '@material-ui/core/colors/pink'
import red from '@material-ui/core/colors/red'
import { dir } from './i18n.ts'

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: { main: '#0593CB' },
    secondary: { main: '#FFFFFF' },
    error: { main: '#B92D00' }
  },
  typography: {
    fontSize: 18,
    fontFamily: ['Assistant'].join(',')
  },
  direction: dir()
})

export const redTheme = createMuiTheme({
  palette: {
    palette: {
      primary: red,
      secondary: pink
    }
  },
  typography: {
    useNextVariants: true
  },
  direction: dir()
})

let plugins = []

if (dir() === 'rtl') {
  plugins = [rtl()]
}

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, ...plugins]
})

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </MuiThemeProvider>
      </StylesProvider>
    )
  }

  return WithRoot
}

export default withRoot
