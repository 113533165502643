import React, { Component } from 'react'
import { Order, price } from 'shared'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { lng } from '../i18n'

const currencyToCode = (currency: string) => {
  // 1-שקל 2-דולר 3-אירו
  // 4 pound
  switch (currency) {
    case 'il':
      return '1'
    case 'de':
    case 'fr':
    case 'eu':
      return '3'
    case 'uk':
      return '4'
    default:
      return '2'
  }
}
class RedirectToPayment extends Component<{ order: Order } & WithNamespaces> {
  private form = React.createRef<HTMLFormElement>()

  componentDidMount() {
    this.form.current!.submit()
  }

  render() {
    const { order, t } = this.props

    return (
      <form ref={this.form} method="post" action="https://icom.yaad.net/p/">
        <input
          type="hidden"
          value={process.env.REACT_APP_PAYMENT_MASOF_NUMBER}
          name="Masof"
        />
        {process.env.REACT_APP_PAYMENT_PASSP && (
          <input
            type="hidden"
            value={process.env.REACT_APP_PAYMENT_PASSP}
            name="PassP"
          />
        )}
        <input type="hidden" value="pay" name="action" />
        <input type="hidden" value={price(order)} name="Amount" />
        <input type="hidden" value={order.uid || ''} name="Order" />
        <input type="hidden" value="True" name="UTF8out" />
        <input type="hidden" value="True" name="UTF8" />
        <input type="hidden" value="1" name="Tash" />
        <input
          type="hidden"
          value={currencyToCode(order.country)}
          name="Coin"
        />
        <input type="hidden" value="4" name="tmp" />
        {lng() === 'en' && (
          <input type="hidden" value="000000000" name="UserId" />
        )}
        <input
          type="hidden"
          value={lng() === 'en' ? 'ENG' : 'HEB'}
          name="PageLang"
        />
        <input type="hidden" value="True" name="Sign" />
        <input type="hidden" value={order.name} name="ClientName" />
        <input
          type="hidden"
          value={`:${order.uid}: ${t('payment.product', {
            defaultValue: 'השתתפות במיזם רצים לזכרם'
          })} ${process.env.REACT_APP_YEAR_HE?.replace('״', '')} ${
            process.env.REACT_APP_YEAR_EN
          }`}
          name="Info"
        />
        <input
          type="hidden"
          value={`${t('payment.product', {
            defaultValue: 'השתתפות במיזם רצים לזכרם'
          })} ${process.env.REACT_APP_YEAR_HE?.replace('״', '')} ${
            process.env.REACT_APP_YEAR_EN
          }`}
          name="heshDesc"
        />
        <input type="hidden" value="True" name="SendHesh" />
        {/* <input type="hidden" value="True" name="MoreData" /> */}
        <input type="hidden" value={order.email} name="email" />
      </form>
    )
  }
}

export default withNamespaces()(RedirectToPayment)
