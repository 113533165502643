import { OrderForm } from '../pages/Registration'
import { AuthUser } from '../utils/WithAuthentication'
import { Order } from 'shared'
import axios from 'axios'

export const saveOrder = async ({
  order,
  language
}: {
  order: OrderForm
  language: string
}): Promise<Order> => {
  const userToken = await window.firebase.auth().currentUser.getIdToken()
  const response = await axios.post(
    `${process.env.REACT_APP_FIREBASE_CLOUD_URL}/orders/`,
    { ...order, language },
    { headers: { Authorization: `Bearer ${userToken}` } }
  )
  return { ...order, uid: response.data.orderId }
}

export const updateOrder = async ({
  order,
  language
}: {
  order: Order
  language: string
}): Promise<Order> => {
  const userToken = await window.firebase.auth().currentUser.getIdToken()
  const response = await axios.patch(
    `${process.env.REACT_APP_FIREBASE_CLOUD_URL}/orders/${
      window.location.search
    }`,
    { ...order, language },
    { headers: { Authorization: `Bearer ${userToken}` } }
  )
  return response.data.orderId
}

export const loadOrdersForUser = async (user: AuthUser): Promise<Order[]> => {
  const userToken = await window.firebase.auth().currentUser.getIdToken()
  const response = await axios.get(
    `${process.env.REACT_APP_FIREBASE_CLOUD_URL}/orders/${
      window.location.search
    }`,
    { headers: { Authorization: `Bearer ${userToken}` } }
  )
  return response.data.orders.map((order: any) => ({
    ...order,
    finalizedAt: new Date(order.finalizedAt)
  }))
}
