import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, createStyles, WithStyles, Theme } from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    text: {
      margin: theme.spacing(2, 0, 0, 0),
      fontSize: '1.125rem',
      textAlign: 'center',
      fontWeight: 'bold',
      color: 'white'
    }
  })

const Alert: React.SFC<WithStyles<typeof styles> & WithNamespaces> = ({
  classes,
  t
}) => {
  useEffect(() => {
    if (window._mfq) {
      window._mfq.push(['setVariable', 'success', true])
    }
  }, [])

  return (
    <Typography className={classes.text} variant="h4" style={{ margin: '0' }}>
      {t('header.thankYouEmail', {
        defaultValue:
          'ברגעים אלו נשלח לכתובת המייל שהזנת מייל עם כל הפרטים ואפשרות להדפסת השמות.'
      })}
    </Typography>
  )
}

export default withNamespaces()(withStyles(styles)(Alert))
