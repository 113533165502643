import React, { Component } from 'react'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import {
  Button,
  Divider,
  Card,
  Chip,
  Avatar,
  Typography,
  CardActions,
  CardContent,
  Theme,
  createStyles,
  WithStyles,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  TextField,
  IconButton
} from '@material-ui/core'
import {
  Info,
  Cancel,
  Save,
  Edit,
  Print,
  Share,
  Face,
  Delete
} from '@material-ui/icons'
import FallenAutocompleteField from '../components/FallenAutocompleteField'
import {
  Registration,
  Fallen,
  fallenParentsName,
  fallenDeathDate,
  fallenIzkorLink,
  racebibLink,
  shareLink
} from 'shared'
import UserDataContext from '../utils/UserDataContext'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { lng, isEnglish } from '../i18n'
import { redTheme } from '../withRoot'
import CloseIcon from '@material-ui/icons/Close'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    card: {
      // minWidth: 275,
      marginTop: theme.spacing(2),
      padding: theme.spacing(1)
    },
    title: {
      marginBottom: 16,
      fontSize: 14,
      color: theme.palette.text.primary
    },
    chip: {
      marginBottom: theme.spacing(1),
      fontSize: 14
    },
    pos: {
      color: '#424242',
      fontSize: '.875rem',
      fontWeight: 'bold'
    },
    box: {
      backgroundColor: '#ECEFF0',
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      borderRadius: '5px',
      position: 'relative'
    },
    checkboxOpen: {
      // padding: theme.spacing(2)
    },
    secondFallen: {
      // marginTop: theme.spacing(4)
    },
    button: {
      // marginTop: theme.spacing(1),
      // marginRight: theme.spacing(2),
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    iconLink: {
      margin: theme.spacing(1, 2, 0, 0),
      padding: theme.spacing(1),
      fontSize: '.875rem',
      color: theme.palette.primary.main
    },
    icon: {
      fontSize: '1.25rem',
      fontWeight: 'bold'
    },
    buttonLink: {
      fontSize: '.875rem',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline'
    },
    marginBtnLink: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    editButtonLink: {
      marginTop: theme.spacing(1),
      // marginRight: theme.spacing(2),
      fontSize: '.875rem',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline'
    },
    actionButton: {
      // width: '100%'
      marginLeft: 'auto',
      fontWeight: 'bold',
      fontSize: '1.25rem'
    },
    name: {
      fontSize: '1.25rem',
      color: theme.palette.primary.main,
      fontWeight: 'bold'
      // marginLeft: theme.spacing(2),
      // marginTop: theme.spacing(1)
    },
    otherFallenDetails: {
      // marginBottom: theme.spacing(1),
      color: '#424242',
      fontSize: '1.125rem'
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    checkbox: {
      color: '#00000080',
      fontSize: '1rem'
    },
    boxBtn: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    actionButtons: {
      width: '100%',
      textAlign: 'right'
    },
    textfield: {
      marginTop: theme.spacing(0)
    }
  })

type FallenCardProps = WithStyles<typeof styles> & {
  registration: Registration
  position: number
}

class FallenCard extends Component<
  FallenCardProps & WithNamespaces,
  { openSelectFallen: boolean; addStringName: boolean; stringName: string }
> {
  constructor(props: FallenCardProps & WithNamespaces) {
    super(props)

    const { registration } = this.props
    const { fallen } = registration
    const { selected } = fallen!

    this.state = {
      openSelectFallen: false,
      addStringName: typeof selected === 'string',
      stringName: selected && typeof selected === 'string' ? selected : ''
    }
  }

  handleSelectFallen = (toSelect: boolean) => {
    this.setState(() => ({ openSelectFallen: toSelect }))
  }

  handleToggleAddStringName = () => {
    this.setState(({ addStringName }) => ({ addStringName: !addStringName }))
  }

  handleOpenRacebib = () => {
    const { registration } = this.props
    const { fallen, org, country } = registration
    const { main, selected } = fallen!

    window.open(
      racebibLink({
        main,
        selected,
        language: lng(),
        encodeLinks: true,
        org,
        country
      }),
      '_blank'
    )
  }

  handleOpenSharing = () => {
    const { registration } = this.props
    const { fallen } = registration
    const { main, selected } = fallen!

    window.open(
      shareLink({ main, selected, language: lng(), encodeLinks: true }),
      '_blank'
    )
  }

  render() {
    const { registration, classes, position, t } = this.props
    const { openSelectFallen, addStringName, stringName } = this.state
    const { fallen } = registration
    const { main, selected } = fallen!

    const selectedFallen: Fallen | undefined = selected as Fallen

    const { fullName, en } = main

    return (
      <UserDataContext.Consumer>
        {({ setSelectedFallen }) => (
          <div style={{ flex: '0 0 100%' }}>
            <Card className={classes.card} elevation={0}>
              <CardContent>
                <Typography variant="body1" className={classes.pos}>
                  {`${t('fallenCard.participantNumber', {
                    defaultValue: 'משתתף/ת'
                  })} ${position}`}
                </Typography>
                <div className={classes.box}>
                  <Typography variant="body2" className={classes.name}>
                    {isEnglish() ? en.fullName : fullName}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.otherFallenDetails}
                  >
                    {fallenParentsName(main, isEnglish())}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.otherFallenDetails}
                  >
                    {fallenDeathDate(main, isEnglish())}
                  </Typography>
                  <Button
                    className={classnames(
                      classes.buttonLink,
                      classes.marginBtnLink
                    )}
                    color="primary"
                    onClick={() => {
                      window.open(fallenIzkorLink(main, isEnglish()), '_blank')
                    }}
                  >
                    {/* <Info className={classes.leftIcon} /> */}
                    {t('fallenCard.mainInfo', {
                      defaultValue: 'למידע על הנופל/ת'
                    })}
                  </Button>
                </div>
                <div className={openSelectFallen ? classes.box : ''}>
                  {/* {!openSelectFallen && !selected && ( */}
                  {(!selected || openSelectFallen) && (
                    <FormControlLabel
                      className={openSelectFallen ? '' : classes.checkboxOpen}
                      control={
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={openSelectFallen}
                          onChange={() => {
                            if (!openSelectFallen) {
                              // Box not open, open it
                              this.handleSelectFallen(true)
                            } else {
                              setSelectedFallen(registration, undefined)
                              this.handleSelectFallen(false)
                            }
                          }}
                          value={!openSelectFallen}
                        />
                      }
                      classes={{
                        label: classes.checkbox
                      }}
                      label={t('fallenCard.selectedOtherNew', {
                        defaultValue: 'אני מעוניין/ת להוסיף שם נוסף לרוץ לזכרו'
                      })}
                    />
                  )}
                  {/* )} */}
                  {openSelectFallen && (
                    <div className={classes.secondFallen}>
                      {!addStringName ? (
                        <FallenAutocompleteField
                          value={
                            selectedFallen && {
                              value: selectedFallen.uuid,
                              label: isEnglish()
                                ? selectedFallen.en.fullName
                                : selectedFallen.fullName
                            }
                          }
                          label={t('fallenCard.selectedQuery', {
                            defaultValue: 'מה שם הנופל/ת?'
                          })}
                          onChange={(selected?: { fallen: Fallen }) => {
                            setSelectedFallen(
                              registration,
                              selected ? selected.fallen : undefined
                            )
                            this.handleSelectFallen(false)
                          }}
                        />
                      ) : (
                        <TextField
                          fullWidth
                          className={classes.textfield}
                          value={stringName}
                          margin="normal"
                          id="otherName"
                          required
                          label={t('fallenCard.selectedOtherQuery', {
                            defaultValue: 'נא להקליד שם אחר'
                          })}
                          onChange={event => {
                            const value: string = event.target.value
                            this.setState(() => ({
                              stringName: value
                            }))
                          }}
                        />
                      )}
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={addStringName}
                              onChange={this.handleToggleAddStringName}
                              color="primary"
                              value="noShirt"
                            />
                          }
                          classes={{
                            label: classes.checkbox
                          }}
                          label={t('fallenCard.addSelectedOtherQuery', {
                            defaultValue: 'להוספת שם אחר (לא ברשימה)'
                          })}
                        />
                      </FormGroup>
                      <div className={classes.actionButtons}>
                        <Button
                          className={classes.buttonLink}
                          color="primary"
                          onClick={() => {
                            this.setState(() => ({
                              addStringName: typeof selected === 'string',
                              openSelectFallen: false,
                              stringName:
                                selected && typeof selected === 'string'
                                  ? selected
                                  : ''
                            }))
                          }}
                        >
                          {t('fallenCard.addSelectedOtherCancel', {
                            defaultValue: 'ביטול'
                          })}
                        </Button>
                        {addStringName && !!stringName && (
                          <>
                            <Button
                              className={classes.button}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                // In case it is empty we set as undefined
                                setSelectedFallen(
                                  registration,
                                  stringName ? stringName : undefined
                                )
                                this.handleSelectFallen(false)
                              }}
                            >
                              {t('fallenCard.addSelectedOtherSave', {
                                defaultValue: 'שמירה'
                              })}
                            </Button>
                            {/* <Button
                              className={classes.button}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                setSelectedFallen(registration)
                                this.handleSelectFallen(false)
                              }}
                            >
                              <Delete className={classes.leftIcon} />
                              {t('fallenCard.addSelectedOtherReset', {
                                defaultValue: 'לאיפוס הבחירה'
                              })}
                            </Button> */}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {!openSelectFallen && !!selectedFallen && (
                  <>
                    <div className={classes.box}>
                      <Typography variant="h4" className={classes.name}>
                        {(isEnglish()
                          ? selectedFallen.en?.fullName
                          : selectedFallen.fullName) || selectedFallen}
                      </Typography>
                      {typeof selectedFallen !== 'string' && (
                        <>
                          <Typography
                            variant="h6"
                            className={classes.otherFallenDetails}
                          >
                            {fallenParentsName(selectedFallen, isEnglish())}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.otherFallenDetails}
                          >
                            {fallenDeathDate(selectedFallen, isEnglish())}
                          </Typography>
                          <Button
                            className={classnames(
                              classes.buttonLink,
                              classes.marginBtnLink
                            )}
                            color="primary"
                            onClick={() => {
                              window.open(
                                fallenIzkorLink(selectedFallen, isEnglish()),
                                '_blank'
                              )
                            }}
                          >
                            {t('fallenCard.selectedInfo', {
                              defaultValue: 'למידע על הנופל/ת'
                            })}
                          </Button>
                        </>
                      )}
                      <div className={classes.boxBtn}>
                        <Button
                          className={classes.editButtonLink}
                          color="primary"
                          onClick={() => this.handleSelectFallen(true)}
                        >
                          {t('fallenCard.selectedNewChoice', {
                            defaultValue: 'עריכה'
                          })}
                        </Button>
                        <IconButton
                          key="close"
                          size="small"
                          aria-label="Close"
                          color="primary"
                          className={classes.iconLink}
                          onClick={() =>
                            setSelectedFallen(registration, undefined)
                          }
                        >
                          <CloseIcon className={classes.icon} />
                        </IconButton>
                      </div>
                    </div>
                  </>
                )}

                {/* {!openSelectFallen && !selected && (
                  <MuiThemeProvider theme={redTheme}>
                    <Button
                      className={classes.button}
                      color="secondary"
                      variant="contained"
                      onClick={() => this.handleSelectFallen(true)}
                    >
                      <Edit className={classes.leftIcon} />
                      {t('fallenCard.selectedOtherNew', {
                        defaultValue: 'אני מעוניין/ת להוסיף שם נוסף לרוץ לזכרו'
                      })}
                    </Button>
                  </MuiThemeProvider>
                )} */}
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={this.handleOpenRacebib}
                >
                  <Print className={classes.leftIcon} />
                  {t('fallenCard.racebib', {
                    defaultValue: 'הדפסה'
                  })}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  onClick={this.handleOpenSharing}
                >
                  <Share className={classes.leftIcon} />
                  {t('fallenCard.sharing', {
                    defaultValue: 'שיתוף'
                  })}
                </Button>
              </CardActions>
            </Card>
          </div>
        )}
      </UserDataContext.Consumer>
    )
  }
}

export default withNamespaces()(withStyles(styles)(FallenCard))
