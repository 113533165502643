import React from 'react'
import { withStyles, Theme } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  createStyles,
  WithStyles
} from '@material-ui/core'
import classnames from 'classnames'

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      margin: theme.spacing(2, 0)
    },
    card: {
      // marginBottom: 16,
      height: '100%',
      overflow: 'visible',
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4)
      },
      boxShadow: '0px 3px 56px #00000029',
      borderRadius: '10px'
    },
    icon: {
      display: 'inline-block',
      verticalAlign: 'sub',
      paddingRight: 5
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: '1.25rem',
      letterSpacing: 0,
      fontWeight: 'bold'
    }
  })

const Section: React.SFC<WithStyles<typeof styles> & {
  title: string
  icon: React.ReactNode
  className?: string
  grid?: { [s: string]: number }
}> = ({ classes, title, children, icon, className, grid = {} }) => (
  <Grid
    item
    xs={12}
    sm={8}
    lg={7}
    xl={7}
    className={classnames(className, classes.grid)}
    {...grid}
  >
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h6" className={classes.title}>
          {/* <div className={classes.icon}>{icon}</div> */}
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  </Grid>
)

export default withStyles(styles)(Section)
