import * as React from 'react'
import ReactGA from 'react-ga'
import { loadOrdersForUser, updateOrder } from '../services/order'
import { Registration, Fallen, Order, Option, ShirtSize } from 'shared'
import { saveRegistrationFallen } from '../services/registration'
import UserDataContext from './UserDataContext'
import { lng } from '../i18n'
declare global {
  interface Window {
    firebase: any
    Raven: any
    _mfq: any
    FB: any
  }
}

export type WithAuthenticationRenderProps = WithAuthenticationState & {
  signOut: () => void
}

export type WithAuthenticationProps = {
  children: (state: WithAuthenticationRenderProps) => React.ReactNode
}

export type AuthUser = {
  uid: string
  phoneNumber: string
}

type WithAuthenticationState = {
  readonly isAuthenticating: boolean
  readonly currentUser?: AuthUser
  readonly orders: Order[]
}

export default class WithAuthentication extends React.Component<
  WithAuthenticationProps,
  WithAuthenticationState
> {
  readonly state: WithAuthenticationState = {
    isAuthenticating: true,
    orders: []
  }

  componentDidMount = () => {
    window.firebase
      .auth()
      .onAuthStateChanged(
        async (firebaseUser?: { uid: string; phoneNumber: string }) => {
          if (firebaseUser) {
            this.setState(() => ({
              currentUser: firebaseUser
            }))

            const orders: Order[] = await loadOrdersForUser(firebaseUser)

            // Set current user
            this.setState(() => ({
              orders,
              isAuthenticating: false
            }))

            window.Raven.setUserContext({
              phoneNumber: firebaseUser.phoneNumber,
              id: firebaseUser.uid
            })

            ReactGA.set({ userId: firebaseUser.uid })

            // Mouseflow tracking
            window._mfq.push(['setVariable', 'userId', firebaseUser.uid])
            window._mfq.push([
              'setVariable',
              'phoneNumber',
              firebaseUser.phoneNumber
            ])
          } else {
            window.Raven.setUserContext()

            ReactGA.set({ userId: undefined })

            this.setState(() => ({
              currentUser: undefined,
              isAuthenticating: false
            }))
          }
        }
      )
  }

  handleSignout = () => {
    this.setState(() => ({
      isAuthenticating: true
    }))
    window.firebase.auth().signOut()
  }

  setCollectionLocation = (
    orderToUpdate: Order,
    collectionLocation: Option
  ): Promise<Order> => {
    let updatedOrder

    const newOrders = this.state.orders.map(order => {
      // If same order, optimistic change
      if (orderToUpdate.uid === order.uid) {
        updatedOrder = {
          ...order,
          collectionLocation
        }

        return updatedOrder
      }
      // if not same, return same object
      return order
    })

    this.setState(() => ({ orders: newOrders }))

    console.log('>>>ORDER')

    return updatedOrder
      ? updateOrder({ order: updatedOrder, language: lng() })
      : Promise.reject()
  }

  setSelectedFallen = (
    registration: Registration,
    fallen?: Fallen | string
  ): Promise<void> => {
    // Update the order in state with the registration
    const newOrders = this.state.orders.map(order => {
      if (order.registrations.find(reg => reg.uid === registration.uid)) {
        // Found registration, replace it with new one
        const newRegistrations = order.registrations.map(reg => {
          if (reg.uid === registration.uid) {
            return {
              ...registration,
              fallen: { main: registration.fallen!.main, selected: fallen }
            }
          }

          return reg
        })
        // return new order object
        return { ...order, registrations: newRegistrations }
      }

      return order
    })

    this.setState(() => ({ orders: newOrders }))

    // Save in REST API
    return saveRegistrationFallen(registration, lng(), fallen)
  }

  setShirtSize = (
    registration: Registration,
    size: ShirtSize
  ): Promise<Order> => {
    let updatedOrder

    // Update the order in state with the registration
    const newOrders = this.state.orders.map(order => {
      if (order.registrations.find(reg => reg.uid === registration.uid)) {
        // Found registration, replace it with new one
        const newRegistrations = order.registrations.map(reg => {
          if (reg.uid === registration.uid) {
            return {
              ...registration,
              size
            }
          }

          return reg
        })
        // return new order object
        updatedOrder = { ...order, registrations: newRegistrations }
        return updatedOrder
      }

      return order
    })

    this.setState(() => ({ orders: newOrders }))

    // Save in REST API
    return updatedOrder
      ? updateOrder({ order: updatedOrder, language: lng() })
      : Promise.reject()
  }

  render() {
    // TODO Add Context API to update Orders (collection place, shirt size) and Registrations (selected fallen/other)
    // TODO Use that API to update state here, start in FallenCard
    return (
      <UserDataContext.Provider
        value={{
          setSelectedFallen: this.setSelectedFallen,
          setCollectionLocation: this.setCollectionLocation,
          setShirtSize: this.setShirtSize
        }}
      >
        {this.props.children({ ...this.state, signOut: this.handleSignout })}
      </UserDataContext.Provider>
    )
  }
}
