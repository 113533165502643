import React from 'react'
import {
  FormControl,
  Input,
  InputLabel,
  createStyles,
  FormHelperText
} from '@material-ui/core'
import { Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { withNamespaces } from 'react-i18next'
import { lng } from '../i18n'

const styles = theme =>
  createStyles({
    label: {
      fontWeight: 'bold',
      position: 'relative'
    }
  })

const PhoneTextField = ({
  id,
  classes,
  validateUntouched,
  shrink,
  t,
  ...props
}) => (
  <Field
    name={id}
    validate={value => {
      if (!isValidPhoneNumber(value)) {
        return t('registration.error.phone.invalid', {
          defaultValue: 'טלפון לא חוקי'
        })
      }
    }}
  >
    {({ field, form }) => {
      const { errors, touched } = form
      const error =
        (validateUntouched || touched[field.name]) && !!errors[field.name]
      const helperText =
        (validateUntouched || touched[field.name]) && errors[field.name]

      return (
        <FormControl error={error} fullWidth margin="normal">
          <InputLabel
            shrink
            htmlFor={`component-${id}`}
            className={classes.label}
          >
            {props.label}
            {props.required ? ' *' : ''}
          </InputLabel>
          <PhoneInput
            defaultCountry="IL"
            {...field}
            numberInputProps={{
              style: { textAlign: lng() === 'he' ? 'right' : 'left' }
            }}
            onChange={value => form.setFieldValue(id, value)}
          />
          {error && (
            <FormHelperText id={`component-${id}-text`}>
              {typeof helperText === 'string'
                ? helperText
                : t('yup.required', {
                    defaultValue: 'שדה חובה'
                  })}
            </FormHelperText>
          )}
        </FormControl>
      )
    }}
  </Field>
)

export const WithoutFormikPhoneField = ({
  id,
  classes,
  shrink,
  error,
  helperText,
  onChange,
  onBlur,
  value,
  t,
  label,
  required,
  ...props
}) => (
  <FormControl error={error} fullWidth margin="normal">
    <InputLabel shrink htmlFor={`component-${id}`} className={classes.label}>
      {label}
      {required ? ' *' : ''}
    </InputLabel>
    <PhoneInput
      defaultCountry="IL"
      value={value}
      numberInputProps={{
        style: { textAlign: lng() === 'he' ? 'right' : 'left' }
      }}
      onChange={onChange}
      onBlur={onBlur}
    />
    {error && (
      <FormHelperText id={`component-${id}-text`}>
        {typeof helperText === 'string'
          ? helperText
          : t('yup.required', {
              defaultValue: 'שדה חובה'
            })}
      </FormHelperText>
    )}
  </FormControl>
)

export default withNamespaces()(withStyles(styles)(PhoneTextField))

export const WithoutFormikPhoneTextField = withNamespaces()(
  withStyles(styles)(WithoutFormikPhoneField)
)
