import React from 'react'
import {
  Typography,
  CircularProgress,
  createStyles,
  WithStyles
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withNamespaces, WithNamespaces } from 'react-i18next'

const styles = () =>
  createStyles({
    root: {
      textAlign: 'center',
      margin: '20px 0'
    },
    text: {
      fontWeight: 'bold',
      marginTop: 40
    }
  })

const SpinnerPage: React.SFC<WithStyles<typeof styles> & WithNamespaces> = ({
  classes,
  t
}) => (
  <div className={classes.root}>
    <CircularProgress color="secondary" size={100} />
    <Typography
      color="secondary"
      className={classes.text}
      variant="h6"
      gutterBottom
    >
      {t('spinner.loading', {
        defaultValue: 'טוען נתונים...'
      })}
    </Typography>
  </div>
)

export default withNamespaces()(withStyles(styles)(SpinnerPage))
