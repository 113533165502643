let algoliaSearchService

export const init = () => {
  if (!window.algoliasearch) {
    throw new Error('Algolia Search could not be loaded.')
  }

  if (!algoliaSearchService) {
    algoliaSearchService = window
      .algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP,
        process.env.REACT_APP_ALGOLIA_API_KEY
      )
      .initIndex(process.env.REACT_APP_ALGOLIA_INDEX_NAME)
  }
}

export const search = query =>
  new Promise((resolve, reject) => {
    algoliaSearchService.search(query, (err, content) => {
      if (!err) {
        resolve(content)
      } else {
        reject(err)
      }
    })
  })

export const getObject = query =>
  new Promise((resolve, reject) => {
    algoliaSearchService.getObject(query, (err, content) => {
      if (!err) {
        resolve(content)
      } else {
        reject(err)
      }
    })
  })
