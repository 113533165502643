import * as React from 'react'
import { Registration, Fallen, Order, Option, ShirtSize } from 'shared'

const UserDataContext = React.createContext({
  setSelectedFallen: (
    registration: Registration,
    fallen?: Fallen | string
  ): void => {},
  setCollectionLocation: (order: Order, collectionLocation: Option): void => {},
  setShirtSize: (registration: Registration, size: ShirtSize): void => {}
})

export default UserDataContext
