import React from 'react'
import { TextField, createStyles } from '@material-ui/core'
import { Field } from 'formik'
import { withStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    label: {
      fontWeight: 'bold'
    }
  })

const TextFieldExpanded = ({ id, classes, validateUntouched, shrink, ...props }) => (
  <Field name={id}>
    {({ field, form: { errors, touched } }) => (
      <TextField
        InputLabelProps={{
          shrink: !!shrink,
          className: classes.label
        }}
        fullWidth
        margin="normal"
        error={
          (validateUntouched || touched[field.name]) && !!errors[field.name]
        }
        helperText={
          (validateUntouched || touched[field.name]) && errors[field.name]
        }
        {...props}
        {...field}
      />
    )}
  </Field>
)

export default withStyles(styles)(TextFieldExpanded)
