import React, { Component } from 'react'
import Authentication from 'pages/Authentication'
import Layout from 'layout/index.tsx'
import Header from 'layout/Header'
// import withLocaleSupport from 'plugins/withLocaleSupport'
import history from 'utils/history'
import ReactGA from 'react-ga'
import { finalizedOrderedOrders } from 'shared'
import Registration from './pages/Registration.tsx'
import SpinnerPage from './pages/Spinner.tsx'
import Home from './pages/Home.tsx'
import withRoot from './withRoot'
import WithAuthentication from './utils/WithAuthentication.tsx'
import { hot } from 'react-hot-loader/root'
import { Container } from '@material-ui/core'

class App extends Component {
  state = {}

  componentWillMount() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)

    this.unlisten = history.listen(location => {
      // location is an object like window.location
      this.setState(() => ({ pathname: location.pathname }))
      // Mouseflow tracking
      window._mfq.push(['newPageView']) // eslint-disable-line
      // GA tracking
      ReactGA.pageview(location.pathname + location.search)
    })

    ReactGA.pageview(history.location.pathname + history.location.search)

    this.setState(() => ({ pathname: history.location.pathname }))
  }

  componentDidUpdate(prevProps, { pathname }) {
    if (this.state.pathname !== pathname) {
      window.scrollTo(0, 0)
    }
  }

  componentWillUnmount() {
    this.unlisten()
  }

  /* eslint-disable */
  componentDidCatch(error, errorInfo) {
    window.Raven.captureException(error, { extra: errorInfo })
  }
  /* eslint-enable */

  render() {
    // const { currentUser, isInitialising } = this.props
    const { pathname } = this.state

    return (
      <WithAuthentication>
        {authenticationProps => {
          const { isAuthenticating, currentUser, orders } = authenticationProps

          const content =
            currentUser && orders ? (
              <>
                <Header isThankYou={pathname === '/' && !!orders.length} />
                {pathname === '/' && !!orders.length ? (
                  <Home orders={finalizedOrderedOrders(orders)} />
                ) : (
                  <Registration currentUser={currentUser} />
                )}
              </>
            ) : (
              <Authentication />
            )

          return (
            <Layout {...authenticationProps} pathname={pathname}>
              {isAuthenticating ? (
                <Container>
                  <Header />
                  <SpinnerPage />
                </Container>
              ) : (
                content
              )}
            </Layout>
          )
        }}
      </WithAuthentication>
    )
  }
}

// export default withRoot(App)

export default process.env.NODE_ENV === 'development'
  ? hot(withRoot(App))
  : withRoot(App)
