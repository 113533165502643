const orgs = [
  {
    id: 'run-community',
    name: 'קהילת רצים'
  },
  {
    id: 'electra',
    name: 'אלקטרה'
  },
  {
    id: 'moatza-yavne',
    name: 'מועצה אזורית חבל יבנה'
  },
  {
    id: 'har-gilon',
    name: 'הר גילון'
  },
  {
    id: 'labon',
    name: 'לבון'
  },
  {
    id: 'moatza-efrat',
    name: 'מועצה מקומית אפרת'
  },
  {
    id: 'even-yehuda',
    name: 'קהילת אבן יהודה'
  },
  {
    id: 'nofei-prat',
    name: 'נופי פרת'
  },
  {
    id: 'ramat-ganim',
    name: 'RamatGanim'
  },
  {
    id: 'palchan55',
    name: 'פלחהן 55'
  },
  {
    id: 'kfar-sabah',
    name: 'עיריית כפר סבא'
  },
  {
    id: 'our-brothers-avi',
    name: 'אחים שלנו: אבינעם בוכריס'
  },
  {
    id: 'our-brothers-bnaia',
    name: 'אחים שלנו: בניה ריין'
  },
  {
    id: 'our-brothers-eliahu',
    name: 'אחים שלנו: אליהו (אלי) יזדי'
  },
  {
    id: 'shaar-shomron',
    name: 'מועצה מקומית שער שומרון'
  },
  {
    id: 'rimonim-tivon',
    name: 'בית ספר רימונים בטבעון'
  },
  {
    id: 'rakefet',
    name: 'הישוב רקפת'
  },
  {
    id: 'namel-haifa',
    name: 'נמל חיפה'
  },
  {
    id: 'moreshet',
    name: 'מורשת'
  },
  {
    id: 'saco-fit',
    name: 'סקו פיט'
  },
  {
    id: 'beit-apel',
    name: 'בית אפל'
  },
  {
    id: 'ormat',
    name: 'Ormat'
  },
  {
    id: 'yasur',
    name: 'יסעור'
  },
  {
    id: 'bank-leumi',
    name: 'בנק לאומי'
  },
  {
    id: 'kfar-masrik',
    name: 'כפר מסריק'
  },
  {
    id: 'no-borders',
    name: 'עמותת גולשים ללא גבולות'
  },
  {
    id: 'harel',
    name: 'חטיבת הראל'
  }
].sort(
  (a, b) =>
    a.name.localeCompare(b.name, 'he', {
      sensitivity: 'base'
    }) || a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
)

export default orgs
