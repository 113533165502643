import React from 'react'
import {
  Typography,
  Grid,
  Container,
  createStyles,
  WithStyles,
  Theme
} from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import LogoImg2X from '../assets/logo-white@2x.png'
import LogoImg from '../assets/logo-white.png'
import ShirtImg from '../assets/shirt.png'
import ShirtImg2X from '../assets/shirt@2x.png'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      top: '0px',
      [theme.breakpoints.up('sm')]: {
        top: '-30px',
        marginBottom: '-80px'
      }
    },
    mainTitle: {
      letterSpacing: 0,
      color: 'white',
      fontWeight: 'bold',
      fontSize: '2.125rem',
      textAlign: 'center',
      margin: theme.spacing(0, 4)
    },
    subTitle: {
      color: 'white',
      fontSize: '1.875rem',
      textAlign: 'center',
      margin: theme.spacing(1, 0, 2, 0)
    },
    logo: {
      margin: theme.spacing(4, 0, 1, 0),
      width: '123px',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    text: {
      margin: theme.spacing(2, 0, 0, 0),
      fontSize: '1.125rem',
      fontWeight: 'bold',
      color: 'white'
    },
    thankyouImg: {
      width: '150px',
      margin: 'auto',
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        width: '250px',
        float: 'right',
        position: 'relative',
        top: '-50px'
      }
    },
    leftTitle: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
        margin: 0
      }
    },
    leftSubtitle: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left'
      }
    }
  })

const Header: React.SFC<WithStyles<typeof styles> &
  WithNamespaces & { isThankYou?: boolean }> = ({ classes, t, isThankYou }) => (
  <Container maxWidth="sm" className={isThankYou ? classes.root : ''}>
    <Grid container direction="row" justify="center" alignItems="center">
      {!isThankYou && (
        <Grid item>
          <img
            alt="Logo"
            srcSet={`${LogoImg2X} 2x`}
            src={LogoImg}
            className={classes.logo}
          />
        </Grid>
      )}
      <Grid item>
        {isThankYou && (
          <img
            alt="Shirt"
            src={ShirtImg}
            srcSet={`${ShirtImg2X} 2x`}
            className={classes.thankyouImg}
          />
        )}
        <Typography
          className={classnames(classes.mainTitle, {
            [classes.leftTitle]: isThankYou
          })}
          variant="h4"
        >
          {isThankYou
            ? t('header.thankYouTitle', {
                defaultValue: 'תודה שנרשמת למירוץ רצים לזכרם'
              })
            : t('header.registration', {
                defaultValue: 'הרשמה למירוץ רצים לזכרם'
              })}
        </Typography>
        <Typography
          variant="h5"
          className={classnames(classes.subTitle, {
            [classes.leftSubtitle]: isThankYou
          })}
        >
          {process.env.REACT_APP_YEAR_EN} / {process.env.REACT_APP_YEAR_HE}
        </Typography>
        {/* {isThankYou && (
          <Typography className={classes.text} variant="h4">
            {t('header.thankYouSubtitle', {
              defaultValue:
                'המערכת בחרה עבורך שם ממאגר הזיכרון אלו שמות הנופל/ים שתרוצו לזכרם:'
            })}
          </Typography>
        )} */}
      </Grid>
    </Grid>
  </Container>
)

export default withNamespaces()(withStyles(styles)(Header))
