import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
  createStyles,
  WithStyles,
  Theme,
  Button,
  TextField
} from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'

import { Order, stores, Option } from 'shared'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import StoreSummary from './StoreSummary'

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      // marginTop: 10
    },
    box: {
      position: 'relative',
      backgroundColor: '#ECEFF0',
      padding: theme.spacing(4),
      margin: theme.spacing(0, 0, 4, 0),
      borderRadius: '5px'
    },
    label: {
      color: '#424242 !important',
      fontWeight: 'bold'
    },
    buttonLink: {
      position: 'absolute',
      zIndex: 1000,
      right: 0,
      top: '15px',
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      fontSize: '.875rem',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline'
    },
    mapLink: {
      fontSize: '.875rem',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      textDecoration: 'underline',
      margin: theme.spacing(2, 0, 0)
    },
    icon: {
      marginRight: theme.spacing(1)
    }
  })

class CollectionEdit extends Component<
  WithStyles<typeof styles> & {
    order: Order
    onCollectionChange: (order: Order, collectionLocation: Option) => void
  } & WithNamespaces,
  {
    editing: boolean
  }
> {
  state: {
    editing: boolean
  } = {
    editing: false
  }

  toggleEditing = () => {
    this.setState(({ editing }) => ({
      editing: !editing
    }))
  }

  render() {
    const { t, order, classes, onCollectionChange } = this.props
    const { editing } = this.state

    const selectedStore = stores.find(
      store =>
        ((store.code as unknown) as string) === order.collectionLocation!.value
    )

    return (
      <div className={classes.box}>
        {!editing && (
          <Button
            className={classes.buttonLink}
            color="primary"
            onClick={() => {
              this.toggleEditing()
            }}
          >
            {t('shirtField.change', {
              defaultValue: 'עריכה'
            })}
          </Button>
        )}
        <Autocomplete
          options={stores
            // .filter(store => !store.disabled)
            .map(({ code, city, area, address }) => ({
              value: code,
              label: `[${area}] ${city} - ${address}`
            }))}
          getOptionLabel={option => option.label}
          value={
            (order.collectionLocation as unknown) as {
              value: number
              label: string
            }
          }
          onChange={(event: any, value: any) => {
            this.toggleEditing()
            onCollectionChange(order, (value as unknown) as Option)
          }}
          noOptionsText={t('selectField.noResults', {
            defaultValue: 'לא נמצאו תוצאות'
          })}
          disableClearable
          disabled={!editing}
          renderInput={params => (
            <TextField
              {...params}
              InputLabelProps={{
                className: classes.label
              }}
              label={t('registration.collectionShirtSelected', {
                defaultValue: 'נקודת איסוף שנבחרה'
              })}
            />
          )}
        />
        <StoreSummary store={selectedStore as any} />
      </div>
    )
  }
}

export default withNamespaces()(withStyles(styles)(CollectionEdit))
