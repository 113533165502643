import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  createStyles,
  WithStyles,
  Theme,
  Button
} from '@material-ui/core'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import LocationOn from '@material-ui/icons/LocationOn'
import MashbirLogo from '../assets/hamashbir.svg'

const styles = (theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: '#ECEFF0',
      padding: theme.spacing(2),
      margin: theme.spacing(2, 0),
      borderRadius: '5px',
      position: 'relative'
    },
    iconButton: {
      paddingLeft: 0,
      margin: theme.spacing(2, 0, 0, 0),
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    icon: {
      margin: '0 5px 0 0'
    },
    bold: {
      fontWeight: 'bold'
    }
  })

const StoreSummary: React.SFC<WithStyles<typeof styles> & {
  store?: {
    code: number
    storeName: string
    area: string
    city: string
    address: string
    wazeUrl: string
  }
} & WithNamespaces> = ({ classes, store, t }) => {
  if (!store) {
    return null
  }

  const { code, storeName, area, city, address, wazeUrl } = store

  return (
    <div className={classes.box}>
      <Typography variant="body2" className={classes.bold}>
        {t('store.title', {
          defaultValue: 'נתוני התחנת איסוף'
        })}
      </Typography>
      {code < 9000 && (
        <img
          src={MashbirLogo}
          alt="Mashbir logo"
          style={{ width: '150px', margin: '8px 0 0' }}
        />
      )}
      <Typography variant="body2">
        {code < 9000 &&
          t('store.mashbir', {
            defaultValue: 'חנות המשביר לצרכן'
          })}{' '}
        {storeName}
      </Typography>
      <Typography variant="body2">
        {city}, {area}
      </Typography>
      <Typography variant="body2">{address}</Typography>
      <Button
        className={classes.iconButton}
        color="primary"
        onClick={() => window.open(wazeUrl, '_blank')}
      >
        <LocationOn className={classes.icon} />
        {t('store.navigate', {
          defaultValue: 'ניווט למקום'
        })}
      </Button>
    </div>
  )
}

export default withNamespaces()(withStyles(styles)(StoreSummary))
