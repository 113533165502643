import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

import translationHE from './locales/he.json'
import translationEN from './locales/en.json'
import Cookies from 'js-cookie'
import qs from 'query-string'

// the translations
const resources = {
  he: {
    translation: translationHE
  },
  en: {
    translation: translationEN
  }
}

const parsedQS = qs.parse(window.location.search)

const defaultFromBrowser = (): string | undefined => {
  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    (navigator as any).userLanguage

  return language ? language[0] + language[1] : language
}

const LANGUAGE_COOKIE = 'language'

const urlParams = new URLSearchParams(window.location.search)
export const lng = (): string =>
  (parsedQS.lng as string) ||
  Cookies.get(LANGUAGE_COOKIE) ||
  // defaultFromBrowser() ||
  'he' // Hebrew default language
export const dir = (): 'ltr' | 'rtl' => (lng() === 'he' ? 'rtl' : 'ltr')

export const isEnglish = (): boolean => lng() === 'en'

export const toggleLanguage = (newLng?: string): void => {
  newLng = newLng || (lng() === 'he' ? 'en' : 'he')
  Cookies.set(LANGUAGE_COOKIE, newLng)
  if (parsedQS.lng) {
    parsedQS.lng = newLng
    window.location.search = qs.stringify(parsedQS)
  } else {
    window.location.reload()
  }
}

if (parsedQS.lng) {
  Cookies.set(LANGUAGE_COOKIE, parsedQS.lng)
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lng(),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

// Setting RTL/LTR
document.getElementById('body')!.dir = dir()

export default i18n
