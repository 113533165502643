import { OrderForm } from '../pages/Registration'
import { AuthUser } from '../utils/WithAuthentication'
import { Registration, Fallen } from 'shared'
import axios from 'axios'

export const saveRegistrationFallen = async (
  registration: Registration,
  language: string,
  fallen?: Fallen | string
): Promise<void> => {
  const userToken = await window.firebase.auth().currentUser.getIdToken()

  const response = await axios.patch(
    `${process.env.REACT_APP_FIREBASE_CLOUD_URL}/orders/registrations/${
      registration.uid
    }/fallen${window.location.search}`,
    {
      selected: fallen,
      language
    },
    { headers: { Authorization: `Bearer ${userToken}` } }
  )
}
