import React, { Component } from 'react'
import TextField from '../components/TextField'
import { Autocomplete } from '@material-ui/lab'
import {
  MenuItem,
  Button,
  Grid,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  createStyles,
  TableHead,
  Container,
  Theme,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  WithStyles,
  FormControl,
  InputLabel,
  Input,
  Select
} from '@material-ui/core'
import PaymentIcon from '@material-ui/icons/Payment'
import FaceIcon from '@material-ui/icons/Face'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import DoneIcon from '@material-ui/icons/Done'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
// import HomeIcon from '@material-ui/icons/Home'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import ShirtField from '../components/ShirtField'
import SelectField from '../components/SelectField'
import PlacesAutocompleteField from '../components/PlacesAutocompleteField'
import classnames from 'classnames'
import qs from 'query-string'
import history from '../utils/history'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'

import {
  Formik,
  Form,
  FieldArray,
  FormikProps,
  FormikErrors,
  FormikActions
} from 'formik'
import * as Yup from 'yup'
import { Persist } from 'formik-persist'
import Section from '../components/Section'
import OrderSummary from '../components/OrderSummary'
import RedirectToPayment from '../components/RedirectToPayment'
import { saveOrder } from '../services/order'
import {
  Order,
  ShirtSize,
  stores,
  shirtSizesGroup,
  peopleLength,
  price,
  priceBySize,
  currency
} from 'shared'
import { AuthUser } from '../utils/WithAuthentication'
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next'
import i18n, { lng } from '../i18n'
import { redTheme } from '../withRoot'
import PhoneTextField from 'components/PhoneTextField'
import StoreSummary from 'components/StoreSummary'
import blockedShirts from '../config/blocked-shirts'
import orgs from '../config/orgs'

const schema = Yup.object().shape({
  name: Yup.string().required(
    i18n.t('yup.required', { defaultValue: 'שדה חובה*' })
  ),
  email: Yup.string()
    .email(i18n.t('yup.email', { defaultValue: 'מייל לא חוקי' }))
    .required(i18n.t('yup.required', { defaultValue: 'שדה חובה*' }))
})

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 20,
      textAlign: 'center'
    },
    big: {
      fontSize: 18
    },
    serverError: {
      color: theme.palette.error.main
    },
    bold: {
      fontWeight: 'bold'
    },
    subtitle: {
      marginBottom: 10,
      textAlign: 'center'
    },
    sectionSubtitle: {
      margin: theme.spacing(1, 0, 0, 0),
      fontSize: '1rem',
      color: '#707070'
    },
    sicum: {
      color: theme.palette.primary.main
    },
    error: {
      textAlign: 'center',
      color: theme.palette.error.main
    },
    logo: {
      margin: '10px auto',
      width: 150,
      display: 'block'
    },
    icon: {
      margin: '0 5px 0 0'
    },
    button: {
      margin: theme.spacing(4, 0, 0, 0),
      fontWeight: 'bold'
    },
    iconButton: {
      paddingLeft: 0,
      margin: theme.spacing(2, 0, 0, 0),
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    buttonCenter: {
      textAlign: 'center'
    },
    margin: {
      margin: theme.spacing(2)
    },
    padding: {
      padding: theme.spacing(0, 2)
    },
    checkbox: {
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    checkboxError: {
      fontWeight: 'bold',
      fontSize: '1rem',
      color: theme.palette.error.main
    },
    buttonProgress: {
      color: theme.palette.primary.dark,
      position: 'absolute',
      top: '50%',
      left: '10%',
      marginTop: -12,
      marginLeft: -12
    },
    tableCell: {
      fontSize: '1rem',
      color: '#707070'
    },
    tableFooterCell: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    errorMsg: {
      color: theme.palette.error.main,
      fontWeight: 'bold',
      margin: theme.spacing(4, 0, 0, 0),
      padding: theme.spacing(2, 0, 2, 0),
      borderTop: `3px solid ${theme.palette.error.main}`,
      borderBottom: `3px solid ${theme.palette.error.main}`
    },
    errorMsgIcon: {
      margin: theme.spacing(0, 0, 0, 4),
      float: 'right'
    },
    checkboxBox: {
      marginTop: theme.spacing(2)
    },
    errors: {
      margin: 0
    }
  })

export type OrderForm = {
  name: string
  email: string
  phone: string
  org: string
  collectionLocation?: {
    value: string
    label: string
  }
  country: 'il' | 'uk' | 'de' | 'fr' | 'us' | 'other'
  activity: {
    type: string
    distance: string
    group: string
    location?: {
      value: string
      label: string
    }
  }
  acceptShirt?: boolean
  registrations: [
    {
      noShirt: boolean
      size: ShirtSize
    }
  ]
}

class Registration extends Component<
  WithStyles<typeof styles> & { currentUser: AuthUser } & WithNamespaces,
  { hasSaveError: boolean; isSaving: boolean; savedOrder?: Order }
> {
  state = {
    hasSaveError: false,
    isSaving: false,
    savedOrder: undefined
  }

  handleOrderSubmit = async (
    values: OrderForm,
    { setSubmitting }: FormikActions<OrderForm>
  ) => {
    // save order to db, set savedOrder in state so it will redirect to payment

    // Submitting will be handled in state as it is a local stuff
    setSubmitting(false)

    this.setState(() => ({
      isSaving: true,
      hasSaveError: false
    }))

    try {
      const savedOrder = await saveOrder({ order: values, language: lng() })
      // TODO set order uid on localstorage of form to save on localstorage
      this.setState(() => ({ savedOrder }))
      // Order will redirect
    } catch (error) {
      this.setState(() => ({ hasSaveError: true, isSaving: false }))
    }
  }

  render() {
    const { classes, currentUser, t } = this.props
    const { savedOrder, hasSaveError, isSaving } = this.state

    const { errorId, errorMsg, org } = qs.parse(history.location.search)

    const errorMessage: { [label: string]: string } = {
      name: t('registration.error.name', {
        defaultValue: 'שם המלא'
      }),
      email: t('registration.error.email', {
        defaultValue: 'דואר אלקטרוני חוקי'
      }),
      phone: t('registration.error.phone', {
        defaultValue: 'טלפון חוקי'
      }),
      collectionLocation: t('registration.error.collectionLocation', {
        defaultValue: 'מקום איסוף חולצה'
      }),
      acceptShirt: t('registration.error.noAcceptShirt', {
        defaultValue: 'אישור שמות לא מודפסים על החולצות'
      })
    }

    return (
      <Container maxWidth="md">
        <Grid item xs={12}>
          {(errorId || errorMsg) && (
            <Grid item xs={12}>
              <Typography
                className={classnames(classes.title, classes.serverError)}
                variant="subtitle1"
                gutterBottom
              >
                {t('registration.error', {
                  defaultValue:
                    'אירעה שגיאה, אם הבעיה נמשכת אנא צור קשר דרך הדף הפייסבוק שלנו.'
                })}{' '}
                ({errorMsg}/{errorId})
              </Typography>
            </Grid>
          )}
        </Grid>
        <Formik
          isInitialValid={false}
          initialValues={{
            name: '',
            email: '',
            phone: currentUser.phoneNumber,
            // phone: '',
            country: 'il',
            acceptShirt: false,
            org: (org as string) || '',
            activity: {
              type: '',
              distance: '',
              group: ''
            },
            registrations: [
              {
                noShirt: false,
                size: 'M'
              }
            ]
          }}
          validate={(values: OrderForm) => {
            const errors: FormikErrors<OrderForm> = {}

            // If exist one registration with shirt and no collectionLocation, show error
            if (
              !!values.registrations.filter(reg => !reg.noShirt).length &&
              !values.collectionLocation
            ) {
              errors.collectionLocation = t('registration.collectionError', {
                defaultValue: 'נא בחר מקום איסוף חולצה'
              })
            }

            // If exist one registration with shirt and no accept shirt
            if (
              !!values.registrations.filter(reg => !reg.noShirt).length &&
              !values.acceptShirt
            ) {
              errors.acceptShirt = t('registration.noAcceptShirt', {
                defaultValue: 'אישור שמות לא מודפסים על החולצה'
              })
            }

            if (
              values.collectionLocation &&
              blockedShirts[
                (values.collectionLocation.value as unknown) as number
              ]
            ) {
              const noSizes = Object.keys(
                blockedShirts[
                  (values.collectionLocation.value as unknown) as number
                ]
              )

              // check if noSizes are in the selected sizes
              const noShirtSelected = values.registrations
                .filter(reg => !reg.noShirt)
                .find(({ size }) => noSizes.includes(size))

              if (noShirtSelected) {
                errors.collectionLocation = t(
                  'registration.noShirtsCollectionError',
                  {
                    defaultValue: `מידות ${noSizes.join(
                      ', '
                    )} נגמרו בנקודת החלוקה הזאת. נא לבחור מידה אחרת או נקודת חלוקה חלופית.`
                  }
                )
              }
            }

            return errors
          }}
          validationSchema={schema}
          onSubmit={this.handleOrderSubmit}
        >
          {({ setFieldValue, isValid, values, errors }) => (
            <Form>
              <Grid item xs={12}>
                {/* <Persist name="new-signup-form" /> */}
                {console.log('>Values', JSON.stringify(values))}
                <Grid
                  spacing={2}
                  container
                  alignItems="stretch"
                  direction="row"
                  justify="center"
                >
                  <Section
                    title={t('registration.myDetailsSection', {
                      defaultValue: 'הפרטים שלי'
                    })}
                    icon={<FaceIcon />}
                  >
                    <TextField
                      id="name"
                      required
                      autoFocus
                      shrink
                      label={t('registration.name', {
                        defaultValue: 'שם מלא'
                      })}
                      // helperText="Please enter first and surname"
                    />
                    <TextField
                      id="email"
                      required
                      shrink
                      type="email"
                      label={t('registration.email', {
                        defaultValue: 'כתובת מייל'
                      })}
                      // helperText="Don't worry we provide your email to someone else"
                    />
                    {/* Future phone here */}
                    {/* <PhoneTextField
                      id="phone"
                      required
                      shrink
                      label={t('registration.phone', {
                        defaultValue: 'טלפון'
                      })}
                      // helperText="Don't worry we provide your email to someone else"
                    /> */}
                    <FormControl fullWidth margin="normal">
                      <InputLabel
                        shrink
                        id="country-label"
                        style={{ fontWeight: 'bold' }}
                      >
                        {t('registration.country', {
                          defaultValue: 'מדינה/אזור'
                        })}{' '}
                        *
                      </InputLabel>
                      <Select
                        labelId="country-label"
                        id="country"
                        displayEmpty
                        value={values.country}
                        onChange={(e: any) =>
                          setFieldValue('country', e.target.value)
                        }
                      >
                        <MenuItem value="il">
                          {t('registration.country.il', {
                            defaultValue: 'ישראל'
                          })}
                        </MenuItem>
                        <MenuItem value="eu">
                          {t('registration.country.eu', {
                            defaultValue: 'אירופה'
                          })}
                        </MenuItem>
                        <MenuItem value="uk">
                          {t('registration.country.uk', {
                            defaultValue: 'אנגליה'
                          })}
                        </MenuItem>
                        <MenuItem value="us">
                          {t('registration.country.us', {
                            defaultValue: 'צפון אמריקה'
                          })}
                        </MenuItem>
                        <MenuItem value="la">
                          {t('registration.country.la', {
                            defaultValue: 'אמריקה לטינית'
                          })}
                        </MenuItem>
                        <MenuItem value="africa">
                          {t('registration.country.africa', {
                            defaultValue: 'אפריקה'
                          })}
                        </MenuItem>
                        <MenuItem value="asia">
                          {t('registration.country.asia', {
                            defaultValue: 'אסיה'
                          })}
                        </MenuItem>
                        <MenuItem value="au">
                          {t('registration.country.au', {
                            defaultValue: 'אוסטרליה'
                          })}
                        </MenuItem>
                        <MenuItem value="other">
                          {t('registration.country.other', {
                            defaultValue: 'אחר'
                          })}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {values.country === 'il' && (
                      <FormControl fullWidth margin="normal">
                        <InputLabel
                          shrink
                          id="org-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          {t('registration.org', {
                            defaultValue: 'שייכות לאירגון'
                          })}
                        </InputLabel>
                        <Select
                          labelId="org-label"
                          id="org"
                          displayEmpty
                          value={values.org}
                          onChange={(e: any) =>
                            setFieldValue('org', e.target.value)
                          }
                        >
                          {[
                            {
                              id: '',
                              name: 'ללא'
                            },
                            ...orgs
                          ].map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {t(`registration.org.${id}`, {
                                defaultValue: name
                              })}
                            </MenuItem>
                          ))}
                          {/* 
                         <MenuItem value="tichon-shamir">
                            {t('registration.org.tichon-shamir', {
                              defaultValue: 'תיכון שמיר תל אביב'
                            })}
                          </MenuItem> */}
                        </Select>
                      </FormControl>
                    )}
                  </Section>
                  <Section
                    title={t('registration.registrationSection', {
                      defaultValue: 'משתתפים'
                    })}
                    icon={<CardMembershipIcon />}
                  >
                    <Typography
                      className={classes.sectionSubtitle}
                      variant="body2"
                    >
                      {t('registration.registrationSectionSubtitle', {
                        defaultValue:
                          'ברישום הבא ניתן לבחור מספר משתתפים. שם הנופל/ת יבחר באופן רנדומלי עבור כל משתתף/ת. בנוסף - ניתן לבחור שם נופל/ת נוסף לאחר ההרשמה.'
                      })}
                    </Typography>
                    {values.country === 'il' && (
                      <Typography
                        className={classes.sectionSubtitle}
                        variant="body2"
                      >
                        {t('registration.registrationSectionSubtitleNoShirt', {
                          defaultValue:
                            'ניתן גם לבחור השתתפות בלי חולצה מתוך הרשימה.'
                        })}
                      </Typography>
                    )}
                    <FieldArray
                      name="registrations"
                      render={arrayHelpers => (
                        <div>
                          {values.registrations.map((reg, idx) => (
                            <ShirtField
                              key={`${reg.size}-${reg.noShirt}-${idx}`} // eslint-disable-line
                              position={idx + 1}
                              onlyNoShirt={values.country !== 'il'}
                              {...reg}
                              onDelete={() => arrayHelpers.remove(idx)}
                              onSizeChange={(size: string) => {
                                if (size === 'noShirt') {
                                  arrayHelpers.replace(idx, {
                                    ...reg,
                                    noShirt: true
                                  })
                                } else {
                                  arrayHelpers.replace(idx, {
                                    ...reg,
                                    size,
                                    noShirt: false
                                  })
                                }
                              }}
                            />
                          ))}
                          <Button
                            className={classes.iconButton}
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                // Add without shirts if country is Israel
                                noShirt: values.country !== 'il',
                                size: 'M'
                              })
                            }
                          >
                            <AddCircleIcon className={classes.icon} />
                            {t('registration.addAdditional', {
                              defaultValue: 'הוספת משתתף/ת נוסף/ת'
                            })}
                          </Button>
                        </div>
                      )}
                    />
                    {!!values.registrations.filter(reg => !reg.noShirt)
                      .length && (
                      <>
                        <Autocomplete
                          id="collectionLocation"
                          noOptionsText={t('selectField.noResults', {
                            defaultValue: 'לא נמצאו תוצאות'
                          })}
                          options={stores
                            // .filter(store => !store.disabled)
                            .map(({ code, city, area, address }) => ({
                              value: code,
                              label: `[${area}] ${city} - ${address}`
                            }))}
                          getOptionLabel={option => option.label}
                          onChange={(event: any, value: any) =>
                            setFieldValue('collectionLocation', value)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              required
                              validateUntouched
                              label={t('registration.collectionShirt', {
                                defaultValue: 'בחר/י נקודת איסוף לחולצות'
                              })}
                            />
                          )}
                        />
                        <StoreSummary
                          store={
                            stores.find(
                              store =>
                                `${store.code}` ===
                                `${values.collectionLocation?.value}`
                            ) as any
                          }
                        />
                        <FormControlLabel
                          className={classes.checkboxBox}
                          control={
                            <Checkbox
                              color="primary"
                              size="small"
                              value={values.acceptShirt}
                              onChange={(e: any) =>
                                setFieldValue(
                                  'acceptShirt',
                                  !values.acceptShirt
                                )
                              }
                            />
                          }
                          classes={{
                            label: errors.acceptShirt
                              ? classes.checkboxError
                              : classes.checkbox
                          }}
                          label={t('registration.resume.confirmation', {
                            defaultValue:
                              'אני מבין/ה שהשמות אינם מודפסים על החולצה, יש להדפיסם בנפרד ולהצמידם לחולצה'
                          })}
                        />
                      </>
                    )}
                  </Section>
                  <Section
                    title={t('registration.resume', {
                      defaultValue: 'סיכום'
                    })}
                    icon={<DoneIcon />}
                  >
                    <Typography
                      className={classes.sectionSubtitle}
                      variant="body2"
                    >
                      {t('registration.summarySectionSubtitle', {
                        defaultValue:
                          'לאחר התשלום תועברו למסך בחירת שמות הנופלים והנופלות לזיכרם/ן תרוצו. במסך הבא תקבלו שם נופל/ת אשר נבחר באופן רנדומלי ממאגר הנופלים.'
                      })}
                    </Typography>
                    <Typography
                      className={classes.sectionSubtitle}
                      variant="body2"
                    >
                      {t('registration.summarySectionSubtitle2', {
                        defaultValue:
                          'בנוסף, תוכלו לבחור במסך זה שם נוסף על-ידי הקלדתו ובחירתו ממאגר הנופלים.'
                      })}
                      <br />
                      <span className={classes.bold}>
                        {t('registration.summarySectionSubtitle3', {
                          defaultValue:
                            'כל ההכנסות יוקדשו לפעילות עמותת ״דרך הרגליים״.'
                        })}
                      </span>
                    </Typography>
                    {isValid ? (
                      <div>
                        <Typography
                          className={classnames(
                            classes.sectionSubtitle,
                            classes.sicum,
                            classes.bold
                          )}
                          variant="body2"
                        >
                          <Trans i18nKey="orderSummary.numberOfPeople">
                            מספר משתתפים: {`${values.registrations.length}`}
                          </Trans>
                        </Typography>
                        {!!values.registrations.filter(reg => !reg.noShirt)
                          .length && (
                          <Typography
                            className={classnames(
                              classes.sectionSubtitle,
                              classes.sicum,
                              classes.bold
                            )}
                            variant="body2"
                          >
                            <Trans i18nKey="orderSummary.location">
                              איסוף מ: {values.collectionLocation?.label}
                            </Trans>
                          </Typography>
                        )}
                        <TableContainer>
                          <Table size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {t('registration.tableType', {
                                    defaultValue: 'סוג'
                                  })}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {t('registration.tableQuantity', {
                                    defaultValue: 'כמות'
                                  })}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {t('registration.tablePrice', {
                                    defaultValue: 'מחיר'
                                  })}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(shirtSizesGroup(values)).map(
                                (size, idx) => (
                                  <TableRow key={idx}>
                                    {size !== 'noShirt' ? (
                                      <TableCell className={classes.tableCell}>
                                        {t('registration.shirtDesc', {
                                          defaultValue: 'חולצה מידה'
                                        })}{' '}
                                        {size}
                                      </TableCell>
                                    ) : (
                                      <TableCell className={classes.tableCell}>
                                        {t('registration.noShirtDesc', {
                                          defaultValue: 'השתתפות ללא חולצה'
                                        })}
                                      </TableCell>
                                    )}
                                    <TableCell className={classes.tableCell}>
                                      {shirtSizesGroup(values)[size]}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {priceBySize(
                                        values.country || 'il',
                                        shirtSizesGroup(values)[size],
                                        size
                                      )}{' '}
                                      {currency(values.country)}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell className={classes.tableFooterCell}>
                                  {t('registration.tableTotal', {
                                    defaultValue: 'סה״כ לתשלום'
                                  })}
                                </TableCell>
                                <TableCell className={classes.tableFooterCell}>
                                  {peopleLength(values)}
                                </TableCell>
                                <TableCell className={classes.tableFooterCell}>
                                  {price(values)} {currency(values.country)}
                                </TableCell>
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : (
                      <Typography
                        component="div"
                        className={classes.errorMsg}
                        variant="body2"
                      >
                        <ReportProblemIcon
                          fontSize="large"
                          className={classes.errorMsgIcon}
                        />
                        {t('orderSummary.error', {
                          defaultValue:
                            'על מנת להמשיך לתשלום, יש למלא את כל השדות הנדרשים'
                        })}
                        {Object.keys(errors).map(error => (
                          <div className={classes.errors} key={error}>
                            - {errorMessage[error]}
                          </div>
                        ))}
                      </Typography>
                    )}
                    {/* <OrderSummary
                      order={values}
                      isOrderInvalid={!isValid}
                      errors={Object.keys(errors).map(
                        error => errorMessage[error]
                      )}
                    /> */}
                    {hasSaveError && (
                      <Typography
                        className={classnames(
                          classes.sectionSubtitle,
                          classes.bold,
                          classes.error
                        )}
                        variant="body2"
                      >
                        {t('registration.formError', {
                          defaultValue:
                            'אירע שגיע בשמירת הנתונים והתקדמות לשלב התשלום, נא לנסות שוב, אם זה לא יצליח נא ליצור קשר איתנו בדף פיסבוק'
                        })}
                      </Typography>
                    )}
                    <Button
                      disabled={!isValid || isSaving}
                      className={classes.button}
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      {/* <PaymentIcon className={classes.icon} /> */}
                      {/* {isSaving && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )} */}
                      {false
                        ? t('registration.paymentWait', {
                            defaultValue: 'נא לחכות, עובר לתשלום'
                          })
                        : t('registration.payment', {
                            defaultValue: 'לתשלום'
                          })}
                    </Button>
                  </Section>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {!!savedOrder && <RedirectToPayment order={savedOrder!} />}
      </Container>
    )
  }
}

export default withNamespaces()(withStyles(styles)(Registration))
